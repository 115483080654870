import React from "react";

const TopBar = ({ language, toggleMenu }) => {
  return (
    <nav className="topbar" aria-label={language.labels.aria.top_menu}>
      <button
        onClick={() => {
          toggleMenu();
        }}
        aria-label={language.labels.aria.open_menu}
        style={{ flex: "0 0 60px" }}
      >
        H
      </button>
      <div className="logo-container">
        {/* <span>Y</span> */}
        <div>{language.labels.brand.hub}</div>
      </div>
      <div style={{ flex: "0 0 60px" }}></div>
    </nav>
  );
};

export default TopBar;
