import React from "react";
import { useNavigate } from "react-router-dom";
import PubBar from "../widgets/public/Bar";

const TimedOut = ({ language }) => {
  const navigate = useNavigate();

  return (
    <>
      <PubBar language={language} />
      <main className="login">
        <div className="join-container" style={{ marginTop: "2em" }}>
          <h1>{language.labels.authentication.timedout}</h1>
          <div
            role="link"
            className="link"
            style={{ padding: "0 0.25em" }}
            aria-label={language.labels.authentication.back_to_signin}
            onClick={() => navigate(`/login`)}
            onKeyUpCapture={(e) => e.key === "Enter" && navigate(`/login`)}
            tabIndex={0}
          >
            {language.labels.authentication.back_to_signin}
          </div>
        </div>
      </main>
    </>
  );
};

export default TimedOut;
