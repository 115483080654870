import React from "react"


const ImageView = ({images, currentImage, setCurrentImage, show, onClose, language}) => {

    const bumpImage = (previous=false) => {
        let n = currentImage
        if(previous){
            n = Math.max(0, n - 1)
        } else {
            n = Math.min(images.length - 1, n + 1)
        }
        setCurrentImage(n)
    }

    if(show){
        return (
            <div className="modal-image">
                <div style={{display:'flex', padding: '0.5em 1em'}}>
                    <div style={{flex:'0 0 1.6em'}}></div>
                    <div className='heading' style={{flex:'1', textAlign:'center', fontSize:'1.1em'}}>{images[currentImage].name}</div>
                    <button className="glyphs img-view" aria-label={language.labels.app.action_close} style={{fontSize:'1.4em'}} onClick={()=>onClose()}>x</button>
                </div>

                <div style={{display:'flex', height: 'Calc(100vh - 4.2em)'}}>
                    <div className="img-view">
                        {(currentImage > 0) ?
                            <button className="glyphs" onClick={()=>bumpImage(true)}>{'<'}</button>
                        :
                            <div className="glyphs" style={{opacity:'0.4'}}>{'<'}</div>
                        }                        
                    </div>
                    <div style={{flex: '1', margin:'auto 0', textAlign: "center"}}>
                        <img className="img-view" src={images[currentImage].src} style={{}} alt={images[currentImage].name} />
                    </div>
                    <div className="img-view">
                        {(currentImage < images.length - 1) ?
                            <button className="glyphs" onClick={()=>bumpImage()}>{'>'}</button>
                        :
                            <div className="glyphs" style={{opacity:'0.4'}}>{'>'}</div>
                        }                        
                    </div>
                </div>
            </div>
        )
    }else{
        return null
    }
}


export default ImageView