import { React, useState, useEffect, useRef } from "react";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";

import config from "../../../../config";
import Modal from "../../../modal/Modal";

const CodeForm = ({
  language,
  animation,
  next,
  def_focus,
  cookies,
  username,
  phoneNumber,
  email,
  password,
  messageResponse,
  setMessageResponse,
}) => {
  ////////////////// INITIALIZE //////////////////
  const modal_message = useRef();
  const modal_notification = useRef();
  const modal_button = useRef();

  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [country, setCountry] = useState("-");

  useEffect(() => {
    if (cookies.get("uuid")) {
      //focus
      def_focus.current.focus();

      //set country
      if (phoneNumber) {
        const phone = parsePhoneNumber(phoneNumber);
        setCountry(phone.country);
      } else {
        setCountry(cookies.get("country"));
      }

      //fix animation
      if (animation && animation.step === 2) {
        if (animation.direction === 1) {
          document.getElementById("code").style.animationName = "slidein";
        } else if (animation.direction === -1) {
          document.getElementById("code").style.animationName = "slideout";
          document.getElementById("code").style.animationDirection = "reverse";
        }
      }
    } else {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (showModal) {
      if (phoneNumber) {
        messageStatus(messageResponse);
      } else {
        modal_notification.current.innerText =
          language.labels.authentication.email_advice;
      }
    }
  }, [showModal]);

  ////////////////// RESUBMIT CODE //////////////////
  const messageStatus = async (data) => {
    modal_button.current.style.display = "none";
    modal_notification.current.style.display = "none";
    modal_message.current.innerText =
      language.labels.authentication.status.checking;
    modal_message.current.className = "dotdotdot";
    const configurationObject = {
      url: `${config.server.api}/recover/message`,
      method: "POST",
      data,
    };
    try {
      const response = await axios(configurationObject);
      modal_message.current.className = "";
      if (language.labels.authentication.sms[response.data.status]) {
        modal_message.current.innerText = language.labels.authentication.sms[
          response.data.status
        ].replace(/{number}/g, response.data.to);

        modal_notification.current.style.display = "";
        if (
          response.data.status === "accepted" ||
          response.data.status === "delivered" ||
          response.data.status === "queued" ||
          response.data.status === "sending" ||
          response.data.status === "scheduled" ||
          response.data.status === "sent"
        ) {
          //pending
          modal_notification.current.innerText =
            language.labels.authentication.status.wait;
        } else {
          //not pending
          modal_notification.current.innerText =
            language.labels.authentication.status.resend;
          modal_button.current.style.display = "";
          modal_button.current.disabled = false;
        }
      } else {
        modal_message.current.innerText = language.labels.error.unknown;
      }
    } catch (err) {
      modal_message.current.innerText = language.labels.error.unknown;
    }
  };

  const reset = async () => {
    try {
      setErrMsg("");
      modal_button.current.disabled = true;
      modal_message.current.innerText =
        language.labels.authentication.code_resetting;
      modal_message.current.className = "dotdotdot";
      modal_notification.current.style.display = "none";

      let post_data = {
        uuid: cookies.get("uuid"),
        language: language.lang,
        country,
      };
      if (phoneNumber) {
        post_data.phone = phoneNumber;
      } else {
        post_data.email = email;
      }

      const configurationObject = {
        url: `${config.server.api}/auth/sendcode`,
        method: "POST",
        data: post_data,
      };
      const response = await axios(configurationObject);
      if (response.status === 200) {
        if (phoneNumber) {
          setMessageResponse(response.data);
          messageStatus(response.data);
        } else {
          modal_message.current.innerText =
            language.labels.authentication.code_reset_successful;
          modal_message.current.className = "";
        }
      } else {
        modal_message.current.innerText =
          language.labels.error[response.data.message];
        modal_message.current.className = "";
      }
    } catch (error) {
      modal_message.current.className = "";
      modal_message.current.innerText = language.labels.error.unknown;
    }
  };

  ////////////////// SUBMIT CODE //////////////////
  const Submit = async (e) => {
    //check inputs
    e.preventDefault();
    if (code) {
      if (code.trim().length !== 6) {
        setErrMsg(language.labels.authentication.code_input_error);
        return;
      }
    } else {
      return;
    }

    try {
      //register the account
      let data = {
        uuid: cookies.get("uuid"),
        password,
        code: code.trim(),
        country,
      };
      if (phoneNumber) {
        data.phone = phoneNumber;
      } else {
        data.email = email;
      }
      const configurationObject = {
        url: `${config.server.api}/auth/register`,
        method: "POST",
        data,
      };
      const response = await axios(configurationObject);
      if (response.status === 200) {
        //go to next step
        document.getElementById("code").style.animationName = "slideout";
        next({ step: 3, direction: 1 });
      } else {
        let errmg = language.labels.error.unknown;
        if (language.labels.error[response.data.message])
          errmg = language.labels.error[response.data.message];
        setErrMsg(errmg);
      }
    } catch (error) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  ////////////////// RESTART //////////////////
  const startOver = () => {
    document.getElementById("code").style.animationName = "slidein";
    document.getElementById("code").style.animationDirection = "reverse";
    next({ step: 0, direction: -1 });
  };

  ////////////////// RENDER GUI //////////////////
  return (
    <>
      <form
        className="login-form signup"
        id="code"
        onSubmit={(e) => Submit(e)}
        aria-label={language.labels.aria.form.signup}
      >
        {/* signup details */}
        <div
          style={{ display: "flex", flexDirection: "row", alignSelf: "center" }}
        >
          <div>{language.labels.authentication.username}</div>
          <div style={{ minWidth: "1rem" }}></div>
          <div className="quote-text">{username}</div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <div>
            {phoneNumber
              ? language.labels.authentication.phone_number
              : language.labels.authentication.email_address}
          </div>
          <div style={{ minWidth: "1rem" }}></div>
          <div className="quote-text">{phoneNumber ? phoneNumber : email}</div>
        </div>

        {/* input code */}
        <div
          className="subtext"
          style={{ marginTop: "1.5rem", fontSize: "0.9em", fontWeight: 300 }}
        >
          {phoneNumber
            ? language.labels.authentication.code_sms_prompt
            : language.labels.authentication.code_mail_prompt}
        </div>
        <input
          ref={def_focus}
          style={{ marginTop: "0.2rem", width: "100%" }}
          placeholder={language.labels.authentication.code}
          aria-label={language.labels.authentication.code}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          tabIndex={0}
        />

        <div style={{ display: "flex" }}>
          <div
            role="link"
            style={{ fontSize: "0.8em", fontWeight: 300 }}
            className="subtext link"
            onClick={() => setShowModal(true)}
            onKeyUpCapture={(e) => e.key === "Enter" && setShowModal(true)}
            tabIndex="0"
          >
            {language.labels.authentication.code_not_received}
          </div>
        </div>

        {errMsg && (
          <div
            style={{ fontWeight: 600, marginTop: "1rem" }}
            className="errtext"
            role="alert"
          >
            {errMsg}
          </div>
        )}

        <button
          className="button"
          type="submit"
          style={{ width: "100%", marginTop: "2rem", fontSize: "1.1rem" }}
        >
          {language.labels.app.action_submit}
        </button>

        <div className="join-container">
          <div
            role="link"
            className="link"
            style={{ fontSize: "0.8em", marginTop: "1em" }}
            onClick={() => startOver()}
            onKeyUpCapture={(e) =>
              (e.key === "Enter" || e.key === " ") && startOver()
            }
            tabIndex="0"
          >
            {language.labels.authentication.signup.start_over}
          </div>
        </div>
      </form>

      {/* Message Status */}
      <Modal
        className="modal-dynamic"
        show={showModal}
        title={
          phoneNumber
            ? language.labels.authentication.status.sms
            : language.labels.authentication.code_not_received
        }
        language={language}
      >
        <div ref={modal_message}></div>
        <div style={{ marginTop: "0.5em" }} ref={modal_notification}></div>

        <div
          style={{
            display: "flex",
            marginTop: "2em",
            gap: "0.25em",
            paddingBottom: "0.5em",
          }}
        >
          <button
            className="button-secondary"
            onClick={() => setShowModal(false)}
          >
            {language.labels.app.ok}
          </button>
          <button ref={modal_button} className="button" onClick={reset}>
            {language.labels.authentication.code_reset}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CodeForm;
