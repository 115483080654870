import { React, useState } from "react";

import { naturalDate } from "../../../lib/date";
import axiosCall from "../../../lib/axios";

const InviteEditor = ({
  invitation,
  language,
  getInvites,
  signout,
  params,
}) => {
  ////////////////// INITIALIZE //////////////////
  const [emailErr, setEmailErr] = useState("");

  ////////////////// RESEND INVITATION //////////////////
  const resend = async () => {
    //reset page errors
    setEmailErr("");

    let result = await axiosCall("invite/resend", {
      inv: invitation._id,
      community: invitation.community,
    });

    if (result.success) {
      if (result.status === 200) {
        params.setEdit(-1);
        getInvites();
      } else {
        setEmailErr(language.labels.error.mailing_invite);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      resend();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  return (
    <div
      className="mobile-center thumb-group-content"
      style={{ position: "relative", maxWidth: "20rem", margin: "10px 0" }}
    >
      {/* close button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          position: "absolute",
          top: 0,
          width: "100%",
          padding: "0.75em",
        }}
      >
        <button
          className="glyphs"
          style={{ fontSize: "1.2em" }}
          onClick={() => params.setEdit(-1)}
        >
          x
        </button>
      </div>

      <div
        className="glyphs font-yongo"
        style={{ fontWeight: 500, fontSize: "2em", marginTop: "0.25em" }}
      >
        E
      </div>
      <div className="handle" style={{ margin: "0.75em 0" }}>
        {invitation.invitee_email}
      </div>
      <div>{language.labels.roles[invitation.role]}</div>
      <div className="handle2" style={{ marginTop: "0.25em" }}>
        {naturalDate(invitation.created, language.locale, language.labels.date)}
      </div>

      {/* revoke */}
      <button
        className="button"
        style={{
          marginTop: "1em",
        }}
        onClick={() => {
          params.revoke(invitation._id);
        }}
      >
        {language.labels.invitation.revoke}
      </button>

      {/* resend */}
      <button
        className="link"
        style={{
          marginTop: "1em",
          fontSize: "0.9em",
        }}
        onClick={() => {
          resend();
        }}
      >
        {language.labels.invitation.resend}
      </button>

      {emailErr && (
        <div
          style={{ marginTop: "0.25em", textAlign: "center" }}
          className="errtext"
          role="alert"
        >
          {emailErr}
        </div>
      )}
    </div>
  );
};
export default InviteEditor;
