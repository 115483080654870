import React, { useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import refreshToken from "../../../lib/refreshToken";
import axiosCall from "../../../lib/axios";
import { formatBytes, url2Key } from "../../../lib/common";

import config from "../../../config";
import Modal from "../../modal/Modal";

const KnowledgeEditor = ({
  editor,
  setEditor,
  getThread,
  language,
  signout,
}) => {
  ////////////// INITIALIZE //////////////////
  const type = editor.knowledge_type || "answer";

  const [headline, setHeadline] = useState(editor.title || null);
  const [post, setPost] = useState(editor.post);

  const [imageFiles, setImageFiles] = useState(editor.images || []);
  const [imageModal, setImageModal] = useState(false);
  const [imageErr, setImageErr] = useState(null);
  const [dlp, setDlp] = useState("");

  const [uploadModal, setUploadModal] = useState(false);
  const [processing, setProcessing] = useState(true);

  const leave_button = useRef();
  const image_key = useRef();

  ////////////// TITLE AND POST //////////////////
  const changeBasic = async (e, change = true) => {
    let val = e.target.getAttribute("name");

    let keyed = null;
    if (e.key) keyed = val;

    //check for changes
    if (
      !change ||
      (val === "title_field" &&
        (headline.trim() === editor.title || headline.trim() === "")) ||
      (val === "post_field" &&
        (post.trim() === editor.post ||
          (post.trim() === "" && editor.knowledge_type !== "question")))
    ) {
      //just reset and close editor
      setBlur(val, true, keyed);
      return;
    }

    // try and submit data
    setDlp("");
    let payload = { id: editor._id };
    if (val === "title_field") payload.title = headline.trim();
    if (val === "post_field") payload.post = post.trim();
    if (editor.answer) payload.answer = true;

    let result = await axiosCall("knowledge/edit", payload);
    if (result.status === 200) {
      getThread();
      if (val === "title_field")
        setEditor({ ...editor, title: headline.trim() });
      if (val === "post_field") setEditor({ ...editor, post: post.trim() });
      setBlur(val, true, keyed);
    } else if (result.status === 201) {
      if (result.data.dlp) {
        setDlp(language.labels.error.dlp_change[result.data.dlp]);
      } else {
        setDlp(language.labels.error.unknown);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeBasic(e, change);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //clear focus
  const clearFocus = (excluding = "") => {
    if (excluding !== "title_field") setHeadline(editor.title);
    if (excluding !== "post_field") setPost(editor.post);
    if (!excluding) setDlp("");
  };
  const setBlur = (name, reblur = false, keyed = null) => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (reblur) {
        document.activeElement.blur();
      } else if (name !== document.activeElement.getAttribute("name")) {
        clearFocus();
      }
      if (keyed) {
        //set focus to next field
        if (keyed === "title_field") {
          if (document.getElementById("post_field"))
            document.getElementById("post_field").focus();
        } else {
          leave_button.current.focus();
        }
      }
    }, 100);
  };

  ////////////// IMAGES //////////////////

  const onImageAdded = () => {
    setUploadModal(false);
    setProcessing(false);
  };

  const addImage = async () => {
    setImageErr(null);
    try {
      const file = document.getElementById("image-file-input").files[0];
      //gather data
      const formdata = new FormData();
      formdata.append("image", file);
      formdata.append("id", editor._id);
      if (editor.answer) formdata.append("answer", editor.answer);

      //call API
      const configurationObject = {
        url: `${
          config.server.api
        }/knowledge/image/add/hub/${localStorage.getItem("client_id")}`,
        method: "POST",
        data: formdata,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        credentials: "include",
      };

      setUploadModal(true);
      setProcessing(true);

      axios(configurationObject)
        .then(async (response) => {
          if (response.status === 202) {
            let r = await refreshToken();
            if (r) {
              //token refreshed try again
              addImage();
            } else {
              //refresh token expired
              signout();
            }
          } else {
            //success
            setProcessing(false);
            getThread();
            let _images = [...imageFiles];
            _images.push(response.data);
            setEditor({ ...editor, images: _images });
            setImageFiles(_images);
          }
        })
        .catch((error) => {
          //unknown error
          signout();
        });
    } catch (err) {
      setImageErr(language.labels.error.file);
    }
  };

  const readImageFile = async (event) => {
    if (event.target.files.length > 0) {
      if (event.target.files[0].size > config.max_attachment) {
        const size = formatBytes(config.max_attachment);
        setImageErr(
          language.labels.error.exceeded_one.replace(/{size}/g, size)
        );
      }

      await new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = async function (event) {
          const arr = new Uint8Array(event.target.result).subarray(0, 3);
          let hexcode = "";
          for (let index = 0; index < 3; index++) {
            if (index > 0) hexcode += "-";
            hexcode += arr[index].toString(16);
          }

          if (config.file.imageCodes.includes(hexcode)) {
            addImage();
          } else {
            setImageErr(language.labels.error.image);
          }
        };
        fileReader.readAsArrayBuffer(event.target.files[0]);
        resolve();
      });
    }
  };

  const removeImageFile = (index) => {
    let key = null;
    if (editor.images[index].signed_url) {
      key = url2Key(editor.images[index].signed_url);
    } else {
      key = editor.images[index].key;
    }
    image_key.current = key;
    if (key) setImageModal(true);
  };

  const deleteImage = async () => {
    let payload = { id: editor._id, key: image_key.current };
    if (editor.answer) payload.answer = editor.answer;
    setImageModal(false);

    let result = await axiosCall("knowledge/image/remove", payload);
    if (result.success) {
      if (result.status === 200) {
        getThread();
        let _images = new Array();
        for (var i = 0; i < imageFiles.length; i++) {
          if (imageFiles[i].key !== result.data) _images.push(imageFiles[i]);
        }
        setEditor({ ...editor, images: _images });
        setImageFiles(_images);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      deleteImage();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// LEAVE //////////////////

  const leave = () => {
    setEditor({});
  };

  ////////////// RENDER GUI //////////////////

  const imageAttachments = imageFiles.map((file, index) => (
    <div
      key={index}
      className="file-container"
      onClick={() => removeImageFile(index)}
      role="img"
      aria-label={file.name}
    >
      <div
        role="button"
        className="close glyphs"
        custom={index}
        tabIndex={0}
        onKeyDown={(e) =>
          (e.key === "Enter" || e.key === " ") && removeImageFile(index)
        }
        aria-label={language.labels.image.remove}
      >
        x
      </div>
      <img
        className="message-uploaded-image"
        src={
          file.signed_url ? file.signed_url : config.content.cache + file.key
        }
        aria-hidden="true"
      />
    </div>
  ));

  return (
    <div>
      <h2
        className="heading2 alert"
        style={{ fontSize: "1.1em", fontWeight: "600", margin: "0 0.25em" }}
      >
        {language.labels.app.edit_mode}
      </h2>

      {editor?.title && (
        <>
          <label
            htmlFor="title_field"
            style={{ display: "block", margin: "1em 0.25em 0 0.25em" }}
          >
            {language.labels.knowledge.headline[editor.knowledge_type]}
          </label>
          <div
            className="natural-edit"
            style={{ display: "flex", maxWidth: "44rem" }}
          >
            <input
              id="title_field"
              value={headline}
              autoComplete="off"
              style={{ flex: 1 }}
              name="title_field"
              onChange={(e) => {
                setHeadline(e.target.value);
              }}
              onFocus={() => clearFocus("title_field")}
              onBlur={() => setBlur("title_field")}
              onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
              maxLength={config.string.title}
            />
            <div
              style={{
                flexBasis: "3.5rem",
                display: "flex",
                justifyContent: "space-around",
                margin: "-0.25rem",
              }}
            >
              <div
                role="button"
                className="glyphs accept"
                name="title_field"
                title={language.labels.app.apply}
                aria-label={language.labels.app.apply}
                onBlur={() => setBlur("title_field")}
                onClick={(e) => changeBasic(e, "title_field")}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e)
                }
                tabIndex={0}
              >
                *
              </div>
              <div
                role="button"
                className="glyphs reject"
                name="title_field"
                style={{ fontSize: "0.7rem" }}
                title={language.labels.app.discard}
                aria-label={language.labels.app.discard}
                onBlur={() => setBlur("title_field")}
                onClick={(e) => {
                  changeBasic(e, false);
                }}
                onKeyDown={(e) =>
                  (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
                }
                tabIndex={0}
              >
                x
              </div>
            </div>
          </div>
        </>
      )}

      {/* Post */}
      <>
        <label
          htmlFor="post_field"
          style={{ display: "block", margin: "1em 0.25em 0 0.25em" }}
        >
          {language.labels.knowledge.your[type]}
        </label>
        <div
          className="natural-edit"
          style={{
            maxWidth: "44rem",
          }}
        >
          <TextareaAutosize
            id="post_field"
            value={post}
            minRows={4}
            autoComplete="off"
            style={{ width: "100%" }}
            name="post_field"
            onChange={(e) => {
              setPost(e.target.value);
            }}
            onFocus={() => clearFocus("post_field")}
            onBlur={() => setBlur("post_field")}
            maxLength={config.string.article}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 0,
              margin: "0 -0.5rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="post_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("post_field")}
              onClick={(e) => {
                changeBasic(e);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="post_field"
              style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("post_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
      </>

      {/* Images */}
      {!editor.pdf && (
        <div style={{ marginTop: "1em" }}>
          <label
            role="button"
            style={{ padding: "0 0.25em" }}
            tabIndex="0"
            htmlFor="image-file-input"
            className="link"
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") &&
              document.getElementById("image-file-input").click()
            }
          >
            {language.labels.app.image_button}
          </label>
          <input
            id="image-file-input"
            style={{ display: "none" }}
            onChange={(e) => readImageFile(e)}
            type="file"
            accept={config.file.imageTypes.toString()}
            multiple={false}
          />
        </div>
      )}

      {imageFiles.length > 0 && (
        <div
          className="message-file-view"
          aria-label={language.labels.aria.selected_images}
        >
          {imageAttachments}
        </div>
      )}
      {imageErr && (
        <div
          style={{ fontWeight: 600, marginTop: "0.5rem" }}
          className="errtext"
          role="alert"
        >
          {imageErr}
        </div>
      )}

      {dlp && (
        <div
          style={{
            fontWeight: 600,
            marginTop: "1.5rem",
            marginBottom: "-1rem",
          }}
          className="errtext"
          role="alert"
        >
          {dlp}
        </div>
      )}

      {/* Leave */}
      <div style={{ display: "flex", gap: "0.25em", margin: "3em 0" }}>
        <button className="button-secondary" ref={leave_button} onClick={leave}>
          {language.labels.app.edit_mode_leave}
        </button>
      </div>

      {/* DELETE IMAGE MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.image.remove_sure}
        onOk={deleteImage}
        onClose={function () {
          setImageModal(false);
        }}
        show={imageModal}
        language={language}
      ></Modal>

      {/* ADD IMAGE MODAL */}
      <Modal className="modal-dynamic" show={uploadModal}>
        {processing ? (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">
              {language.labels.image.uploading.process}
            </h2>
            <div
              className="dotdotdot"
              style={{ padding: "0.5rem", fontSize: "1.1em" }}
            >
              {language.labels.image.uploading.processing}
            </div>
          </div>
        ) : (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.app.done}</h2>
            <div style={{ padding: "0.5rem", fontSize: "1.1em" }}>
              {language.labels.image.uploading.done}
            </div>
            <button
              className="button"
              style={{ margin: "1em 0.25em" }}
              onClick={() => onImageAdded()}
            >
              {language.labels.app.ok}
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default KnowledgeEditor;
