import React, { useState, useRef } from "react";

const ImageEditor = React.forwardRef(
  (
    { file, setFile, language, style, apply, clear, radius = "0", dim = 120 },
    ref
  ) => {
    ////////////// INITIALIZATION //////////////////
    //set image states
    const dimpx = dim + "px";
    const [PLimg, setPLimg] = useState(new Image());
    const [aspect, setAspect] = useState(1);
    const [imageWidth, setImageWidth] = useState(dim);
    const [imageHeight, setImageHeight] = useState(dim);
    const [zoom, setZoom] = useState(0);
    const [xOffset, setXOffset] = useState(0);
    const [yOffset, setYOffset] = useState(0);
    const [theta, setTheta] = useState(0);
    const [invalid, setInvalid] = useState(false);

    const image_form = useRef();

    ////////////// FILE FUNCTIONS //////////////////
    //drag and drop
    const onDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("crest-settings").classList.add("hovering");
    };
    const onDragEnter = (e) => {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("crest-settings").classList.add("hovering");
    };
    const onDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    //file handling
    const readDroppedFile = (e) => {
      e.preventDefault();
      e.stopPropagation();
      document.getElementById("crest-settings").classList.remove("hovering");
      var droppedFile = e.dataTransfer.items[0].getAsFile();
      if (e.dataTransfer.items[0]) {
        readFile(droppedFile);
      }
    };
    const readSearchedFile = (e) => {
      if (e.target.files && e.target.files[0]) {
        readFile(e.target.files[0]);
      } else {
        console.error("something went wrong. No file has been chosen");
      }
    };
    const readFile = (chosenFile) => {
      setAspect(1);
      setFile(chosenFile);
      setXOffset(0);
      setYOffset(0);
      setZoom(0);
      setTheta(0);

      var canvas = ref.current;
      var ctx = canvas.getContext("2d");
      setInvalid(false);

      var imageType = /image.*/;
      if (chosenFile.type.match(imageType)) {
        var reader = new FileReader();
        reader.onload = function () {
          PLimg.src = reader.result;
          PLimg.onload = function () {
            var x = PLimg.width;
            var y = PLimg.height;

            //reset original size
            let aspectRatio = x / y;
            setAspect(aspectRatio);
            if (aspectRatio < 1) {
              x = dim;
              y = Math.round(dim / aspectRatio, 1);
            } else {
              y = dim;
              x = Math.round(dim * aspectRatio, 1);
            }
            setImageWidth(x);
            setImageHeight(y);

            var l = Math.floor((dim - x) / 2);
            var t = Math.floor((dim - y) / 2);

            ctx.restore();
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(this, l, t, x, y);
            ctx.save();
            setPLimg(this);
          };
        };
        reader.readAsDataURL(chosenFile);
      } else {
        setInvalid(true);
        setFile(null);
        image_form.current.reset();
      }
    };

    //remove file
    const removeCrest = (e) => {
      if (file) reset(true);
      apply(e, true, true);
    };

    ////////////// DRAWING FUNCTIONS //////////////////
    const redraw = (angle, scale, dx, dy) => {
      //get zoom
      let size;
      if (scale < 100) {
        size = 0.05 * scale + 1;
      } else {
        size = 0.1 * scale + 1;
      }

      //step 1. reset
      let canvas = ref.current;
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, 1.5 * dim, 1.5 * dim);
      ctx.resetTransform();
      ctx.clearRect(0, 0, 1.5 * dim, 1.5 * dim);

      //step 2. zoom
      let x;
      let y;
      if (aspect < 1) {
        x = 1 + Math.floor(dim * size);
        y = 1 + Math.floor((dim * size) / aspect);
      } else {
        x = 1 + Math.floor(dim * size * aspect);
        y = 1 + Math.floor(dim * size);
      }
      let l = Math.floor((dim - x) / 2);
      let t = Math.floor((dim - y) / 2);

      //step 3. translate
      ctx.translate(dx * 1.6, dy * 1.6);

      //step 4. rotate
      ctx.translate(60, 60);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.translate(-60, -60);

      //step 5. redraw
      ctx.drawImage(PLimg, l, t, x, y);
    };

    //zoom
    const resize = (e) => {
      let scale = e.target.value;
      redraw(theta, scale, xOffset, yOffset);
      setZoom(e.target.value);
    };

    //move left/right orup/down
    const move = (e, dir) => {
      if (dir === "x") {
        let dx = e.target.value;
        redraw(theta, zoom, dx, yOffset);
        setXOffset(e.target.value);
      } else {
        let dy = e.target.value;
        redraw(theta, zoom, xOffset, dy);
        setYOffset(e.target.value);
      }
    };

    //rotate
    const rotate = (e) => {
      let angle = e.target.value;
      redraw(angle, zoom, xOffset, yOffset);
      setTheta(e.target.value);
    };

    //reset
    const reset = (close) => {
      setXOffset(0);
      setYOffset(0);
      setZoom(0);
      setTheta(0);

      let canvas = ref.current;
      var ctx = canvas.getContext("2d");
      ctx.resetTransform();
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (close) {
        setFile(null);
        image_form.current.reset();
        document.getElementById("crest-settings").focus();
      } else {
        var l = Math.floor((dim - imageWidth) / 2);
        var t = Math.floor((dim - imageHeight) / 2);
        ctx.drawImage(PLimg, l, t, imageWidth, imageHeight);
      }
    };

    ////////////// KEYBOARD ACTIONS //////////////////
    //open file selector
    const pickFile = (e) => {
      //stopPropagation not working so check that settings is pressed
      if (
        e.target.id === "crest-settings" &&
        (e.key === "Enter" || e.key === " ")
      )
        document.getElementById("file_field").click();
    };

    ////////////// RENDER GUI //////////////////
    // refer to accessibility guide https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role
    const options = (
      <div style={{ display: "flex", alignItems: "center" }}>
        {clear && (
          <button
            className="subtext link"
            style={{ marginTop: "4px" }}
            onClick={(e) => {
              removeCrest(e);
            }}
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") && removeCrest(e)
            }
          >
            {language.labels.image.remove}
          </button>
        )}
        {apply && (
          <div
            style={{
              display: "flex",
              flex: "1",
              justifyContent: "flex-end",
              marginTop: "4px",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              style={{ margin: "0 0.4rem" }}
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onClick={(e) => {
                apply(e);
              }}
              onKeyUpCapture={(e) =>
                (e.key === "Enter" || e.key === " ") && apply(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onClick={(e) => {
                apply(e, false);
              }}
              onKeyUpCapture={(e) =>
                (e.key === "Enter" || e.key === " ") && apply(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        )}
      </div>
    );

    return (
      <section style={style} aria-label={language.labels.aria.image.selector}>
        <form
          style={{ position: "relative", marginTop: "5px" }}
          ref={image_form}
        >
          <input
            id="file_field"
            style={{ display: "none " }}
            onChange={(e) => {
              readSearchedFile(e);
            }}
            type="file"
            accept="image/*"
          />

          <div
            role="button"
            aria-label={
              invalid
                ? language.labels.image.file_invalid
                : language.labels.image.drag_drop
            }
            className={`crest-edit-container ${invalid && "invalid"}`}
            id="crest-settings"
            style={{ display: "flex", position: "relative" }}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            radius
            onDragEnter={onDragEnter}
            onDrop={(e) => readDroppedFile(e)}
            onKeyDown={(e) => pickFile(e)}
            tabIndex="0"
          >
            <label
              aria-hidden="true"
              style={{
                display: file ? "none" : "unset",
                position: "relative",
                zIndex: 0,
                cursor: "pointer",
                flex: 1,
                textAlign: "center",
              }}
              htmlFor="file_field"
            >
              {invalid
                ? language.labels.image.file_invalid
                : language.labels.image.drag_drop}
            </label>
            <div
              className="crest-box"
              style={{ display: file ? "flex" : "none", borderRadius: radius }}
            >
              <canvas
                ref={ref}
                style={{ borderRadius: radius }}
                width={dimpx}
                height={dimpx}
              ></canvas>
            </div>
          </div>

          {file && (
            <input
              className="sr-only        "
              aria-label={language.labels.aria.image.selected}
              value={file.name}
              onKeyUpCapture={(e) => e.key === "Enter" && apply && apply(e)}
              readOnly
            />
          )}

          {/* controls can't be nested for accessibility reasons */}
          <div
            className="crest-settings"
            style={{ display: file ? "flex" : "none" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                htmlFor="zoom_control"
                style={{ fontSize: ".7rem", padding: "0 .5rem" }}
              >
                {language.labels.image.zoom}
              </label>
              <input
                id="zoom_control"
                aria-label={language.labels.aria.image.zoom}
                className="crest-ranger"
                onChange={(e) => resize(e)}
                type="range"
                min={-15}
                max={15}
                step={0.2}
                value={zoom}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <label
                htmlFor="x_control"
                style={{ fontSize: ".7rem", padding: "0 .5rem" }}
              >
                {language.labels.image.x_axis}
              </label>
              <input
                id="x_control"
                aria-label={language.labels.aria.image.x}
                className="crest-ranger"
                onChange={(e) => move(e, "x")}
                type="range"
                min={-100}
                max={100}
                step={1}
                value={xOffset}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <label
                htmlFor="y_control"
                style={{ fontSize: ".7rem", padding: "0 .5rem" }}
              >
                {language.labels.image.y_axis}
              </label>
              <input
                id="y_control"
                aria-label={language.labels.aria.image.y}
                className="crest-ranger"
                onChange={(e) => move(e, "y")}
                type="range"
                min={-100}
                max={100}
                step={5}
                value={yOffset}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "8px",
              }}
            >
              <label
                htmlFor="rotate_control"
                style={{ fontSize: ".7rem", padding: "0 .5rem" }}
              >
                {language.labels.image.rotate}
              </label>
              <input
                id="rotate_control"
                aria-label={language.labels.aria.image.rotate}
                className="crest-ranger"
                onChange={(e) => rotate(e)}
                type="range"
                min={-180}
                max={180}
                step={1.5}
                value={theta}
              />
            </div>

            <div style={{ display: "flex", marginTop: "4px" }}>
              <div
                role="button"
                className="link"
                style={{
                  alignSelf: "flex-end",
                  fontSize: ".8rem",
                  padding: ".2rem .3rem",
                }}
                onClick={() => reset(false)}
                onKeyUpCapture={(e) =>
                  (e.key === "Enter" || e.key === " ") && reset(false)
                }
                tabIndex="0"
              >
                {language.labels.image.reset}
              </div>
              <div style={{ flex: 1 }}></div>
              <div
                role="button"
                className="link"
                style={{
                  alignSelf: "flex-end",
                  fontSize: ".8rem",
                  padding: ".2rem .3rem",
                }}
                onClick={() => reset(true)}
                onKeyUpCapture={(e) =>
                  (e.key === "Enter" || e.key === " ") && reset(true)
                }
                tabIndex="0"
              >
                {language.labels.image.clear}
              </div>
            </div>
          </div>
        </form>
        {options}
      </section>
    );
  }
);

export default ImageEditor;
