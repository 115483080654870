import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CsvDownloader from "react-csv-downloader";

import axiosCall from "../../lib/axios";

const Admin = ({ language, main_focus, signout }) => {
  //////////// INITIALIZE ///////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);

  const communityHeader = [
    { id: "_id", displayName: "Community Number" },
    { id: "uname", displayName: "Community ID" },
    { id: "title", displayName: "Community Name" },
    { id: "type", displayName: "Type" },
    { id: "remittance", displayName: "Remittance" },
    { id: "active", displayName: "Active" },
    { id: "created", displayName: "Created (Y-M-D)" },
    { id: "verified", displayName: "Verified" },
    { id: "exclusive", displayName: "Exclusive" },
    { id: "enterprize_cap", displayName: "Enterprise Accounts Granted" },
  ];

  //////////// DATA FUNCTIONS ///////////

  const communityReport = async (attempt = 1) => {
    try {
      let result = await axiosCall("admin/communities");
      if (result.success) {
        return result.data;
      } else if (result.refresh) {
        throw new Error();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    } catch (err) {
      if (attempt < 2) {
        return communityReport(attempt + 1);
      } else {
        //unknown error
        signout();
      }
    }
  };

  if (auth.admin) {
    return (
      <main className="main-page" ref={main_focus} tabIndex={0}>
        <div
          className="page-section mobile-justify"
          style={{ display: "flex" }}
        >
          <h1 className="heading">{language.labels.app.admin.toUpperCase()}</h1>
        </div>

        <div className="page-section">
          <div className="text-label" style={{ fontSize: "1.1em" }}>
            Downloads
          </div>
          <div style={{ marginTop: "0.5em" }}>
            <span>&nbsp; - &nbsp;</span>
            <CsvDownloader
              style={{ display: "inline" }}
              className="link"
              filename="community list"
              columns={communityHeader}
              datas={communityReport}
            >
              Community List
            </CsvDownloader>
          </div>
        </div>

        <div className="page-section" style={{ marginTop: "1em" }}>
          <div className="text-label" style={{ fontSize: "1.1em" }}>
            Community Lookup
          </div>
        </div>
      </main>
    );
  } else {
    return <></>;
  }
};
export default Admin;
