import React, { useState, useEffect, useRef, forwardRef } from "react";
import TextareaAutosize from "react-textarea-autosize";

import config from "../../config";
import { ImageEditor } from ".";
import axiosCall from "../../lib/axios";

const PathwayEditor = ({
  language,
  pathway,
  setPathway,
  main_focus,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  //Basic details
  const [title, setTitle] = useState(pathway.pathway);
  const [description, setDescription] = useState(pathway.description);
  const [already, setAlready] = useState(null);

  //////////// MISC FUNCTIONS ///////////

  function resetPathway(obj) {
    let _pathway = { ...pathway };
    //update block
    for (const key in obj) {
      _pathway[key] = obj[key];
    }
    setPathway(_pathway);
  }

  //focus and blur
  const clearFocus = () => {
    setTitle(pathway.pathway);
    setDescription(pathway.description);
  };
  const setBlur = (e, reblur = false, keyed = null) => {
    //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
    setTimeout(() => {
      if (reblur) {
        document.activeElement.blur();
      } else if (
        e.getAttribute("name") !== document.activeElement.getAttribute("name")
      ) {
        clearFocus();
      }
      if (keyed) {
        //set focus to next field
        let arr = ["title_field", "about_field"];
        const next = arr.indexOf(keyed) + 1;
        if (next > 0 && next < arr.length) {
          document.getElementById(arr[next]).focus();
        } else {
          main_focus.current.focus();
        }
      }
    }, 100);
  };

  ////////////// TEXT DETAILS //////////////

  const changeBasic = async (e, change = true) => {
    let val = e.target.getAttribute("name");
    let keyed = null;
    if (e.key) keyed = val;
    setAlready(null);

    //check for changes
    if (
      !change ||
      (val === "title_field" &&
        (title === pathway.pathway || title.trim() === "")) ||
      (val === "description_field" &&
        (description === pathway.description || description.trim() === ""))
    ) {
      //just reset and close editor
      setBlur(val, true, keyed);
      return;
    }

    //get data
    let data = { pid: pathway._id };
    if (val === "title_field") {
      data["pathway"] = title.trim();
    } else if (val === "description_field") {
      data["description"] = description.trim();
    }

    let result = await axiosCall("pathway/edit", data);
    if (result.success) {
      if (result.status === 200) {
        resetPathway(data);
      } else {
        setBlur(val, true, keyed);
        if (val === "title_field") setAlready(title.trim());
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      changeBasic(e);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// RENDER GUI ///////////
  return (
    <>
      {/* TITLE */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "44rem",
          marginTop: "1.5rem",
        }}
      >
        <label className="text-label" htmlFor="title_field">
          {language.labels.pathways.title}
        </label>
        <div
          className="natural-edit"
          style={{ display: "flex", maxWidth: "44rem" }}
        >
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="title_field"
            name="title_field"
            value={title}
            placeholder={language.labels.tips.enter}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => clearFocus()}
            onBlur={(e) => setBlur(e.target)}
            onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
            maxLength={config.string.title}
          />
          <div
            style={{
              flexBasis: "3.5rem",
              display: "flex",
              justifyContent: "space-around",
              margin: "-0.25rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="title_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => changeBasic(e, "title_field")}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="title_field"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
      </div>

      {already && (
        <div
          style={{ fontWeight: 600, padding: "0.5em 0 0 0" }}
          className="errtext"
          role="alert"
        >
          {language.labels.pathways.already.replace(/{title}/g, already)}
        </div>
      )}

      {/* DESCRIPTION */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "44rem",
          marginTop: "1.5rem",
        }}
      >
        <label className="text-label" htmlFor="description_field">
          {language.labels.tips.enter_description}
        </label>
        <div
          className="natural-edit"
          style={{ display: "flex", maxWidth: "44rem" }}
        >
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="description_field"
            name="description_field"
            value={description}
            placeholder={language.labels.tips.pathway_description}
            onChange={(e) => setDescription(e.target.value)}
            onFocus={() => clearFocus()}
            onBlur={(e) => setBlur(e.target)}
            onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
            maxLength={config.string.email}
          />
          <div
            style={{
              flexBasis: "3.5rem",
              display: "flex",
              justifyContent: "space-around",
              margin: "-0.25rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="description_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => changeBasic(e, "description_field")}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="description_field"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PathwayEditor;
