import React from "react";

const Modal = ({
  html,
  children,
  className,
  title,
  show,
  onClose,
  onOk,
  language,
}) => {
  const class_style = className || "";
  if (show) {
    return (
      <div className="modal">
        <div
          id="modal-content"
          className={`modal-content ${class_style}`}
          onClick={(e) => e.stopPropagation()}
        >
          {title && (
            <div className="modal-heading">
              <h2
                style={{
                  fontWeight: "600",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {title}
              </h2>
              <div style={{ flexGrow: 1 }}></div>
            </div>
          )}
          {children && <div style={{ paddingBottom: "1em" }}>{children}</div>}
          {html && (
            <div
              style={{ paddingBottom: "1em" }}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          {onOk && (
            <div
              style={{
                display: "flex",
                marginTop: "1em",
                gap: "0.25em",
                paddingBottom: "0.5em",
              }}
            >
              <button className="button-on" onClick={onOk}>
                {language.labels.app.ok}
              </button>
              <button className="button-off" onClick={onClose}>
                {language.labels.app.cancel}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Modal;
