import React from "react";
import { useNavigate } from "react-router-dom";
import { GetApp } from "../widgets";

const Welcome = ({ language, os, main_focus }) => {
  //////////// INITIALIZE ////////////
  const navigate = useNavigate();

  //////////// RENDER GUI ////////////
  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div
        className="page-section"
        style={{
          paddingBottom: "3em",
          maxWidth: "44rem",
        }}
      >
        <h1 className="heading public-center">
          {language.labels.app.welcome_hub}
        </h1>

        <div
          className="public-center"
          style={{ marginTop: "0.5em", fontSize: "1.2em" }}
        >
          {language.labels.brand.about_hub}
        </div>

        <div className="public-center" style={{ margin: "1em 0" }}>
          <button
            id="new_link"
            className="button"
            style={{ fontSize: "1.1em" }}
            onClick={() => {
              navigate("/newcommunity");
            }}
          >
            {language.labels.communities.start_community}
          </button>
        </div>

        <div className="public-center" style={{ margin: "2em 0" }}>
          <div>{language.labels.brand.about_app}</div>
          <button
            className="link"
            style={{ marginTop: "0.5em", fontSize: "1.1em" }}
            onClick={() => navigate("/gotoapp")}
          >
            {language.labels.app.launch_go}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Welcome;
