import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";

import config from "../../../../config";
import validator from "../../../../lib/validation";
import { togglePassword } from "../../../../lib/common";
import Modal from "../../../modal/Modal";

const CodePage = ({
  language,
  animation,
  def_focus,
  registrations,
  userName,
  messageResponse,
  setMessageResponse,
}) => {
  ////////////////// INITIALIZE //////////////////
  const navigate = useNavigate();

  const cookies = new Cookies();

  const pwd_field = useRef();
  const modal_message = useRef();
  const modal_notification = useRef();
  const modal_button = useRef();

  const [showPassword, setShowPassword] = useState(false);
  const [passwordErr, setPasswordErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (animation && animation === 3) {
      document.getElementById("code").style.animationName = "slidein";
    }
  }, []);

  useEffect(() => {
    if (showModal) messageStatus(messageResponse);
  }, [showModal]);

  ////////////////// RESUBMIT CODE //////////////////
  const messageStatus = async (data) => {
    modal_button.current.style.display = "none";
    modal_notification.current.style.display = "none";
    modal_message.current.innerText =
      language.labels.authentication.status.checking;
    modal_message.current.className = "dotdotdot";
    const configurationObject = {
      url: `${config.server.api}/recover/message`,
      method: "POST",
      data,
    };
    try {
      const response = await axios(configurationObject);
      modal_message.current.className = "";
      if (language.labels.authentication.sms[response.data.status]) {
        modal_message.current.innerText = language.labels.authentication.sms[
          response.data.status
        ].replace(/{number}/g, response.data.to);

        modal_notification.current.style.display = "";
        if (
          response.data.status === "accepted" ||
          response.data.status === "delivered" ||
          response.data.status === "queued" ||
          response.data.status === "sending" ||
          response.data.status === "scheduled" ||
          response.data.status === "sent"
        ) {
          //pending
          modal_notification.current.innerText =
            language.labels.authentication.status.wait;
        } else {
          //not pending
          modal_notification.current.innerText =
            language.labels.authentication.status.resend;
          modal_button.current.style.display = "";
          modal_button.current.disabled = false;
        }
      } else {
        modal_message.current.innerText = language.labels.error.unknown;
      }
    } catch (err) {
      modal_message.current.innerText = language.labels.error.unknown;
    }
  };

  const reset = async () => {
    try {
      setErrMsg("");
      modal_button.current.disabled = true;
      modal_message.current.innerText =
        language.labels.authentication.code_resetting;
      modal_message.current.className = "dotdotdot";
      modal_notification.current.style.display = "none";
      const configurationObject = {
        url: `${config.server.api}/recover/code`,
        method: "POST",
        data: {
          recovery: cookies.get("recovery"),
          collection: "phoneNumber",
          id: registrations.phone.id,
          language: language.lang,
        },
      };
      const response = await axios(configurationObject);
      if (response.status === 200) {
        setMessageResponse(response.data);
        messageStatus(response.data);
      } else {
        modal_message.current.innerText =
          language.labels.error[response.data.message];
        modal_message.current.className = "";
      }
    } catch (error) {
      modal_message.current.className = "";
      modal_message.current.innerText = language.labels.error.unknown;
    }
  };

  ////////////////// PROCESS CODE //////////////////
  const clearErrors = () => {
    setErrMsg("");
    setPasswordErr("");
  };

  const submit = async (e) => {
    //init
    e.preventDefault();
    clearErrors();
    let post_data = { username: userName, recovery: cookies.get("recovery") };
    let code = def_focus.current.value || "";
    let password = pwd_field.current.value;

    //check code
    if (code.trim()) {
      post_data.code = code.trim();
    } else {
      def_focus.current.focus();
      return;
    }

    //check password
    var pwd_chk = validator.passwordValidator(password);
    if (pwd_chk) {
      post_data.password = password;
    } else {
      if (password)
        setPasswordErr(language.labels.authentication.password_invalid);
      pwd_field.current.focus();
      return;
    }

    try {
      document.getElementById("loader").style.display = "inline";
      const configurationObject = {
        url: `${config.server.api}/recover/password`,
        method: "POST",
        data: post_data,
      };
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        sessionStorage.setItem("password_reset", "OK");
        setSuccess(true);
      } else if (response.status === 201) {
        setErrMsg(language.labels.error.code_invalid);
      } else {
        setErrMsg(language.labels.error.unknown);
      }
    } catch (err) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  ////////////////// RENDER GUI //////////////////
  return (
    <>
      {success ? (
        <div style={{ textAlign: "center" }}>
          <div
            role="status"
            style={{
              fontWeight: "500",
              fontSize: "1.2em",
              paddingBottom: "1em",
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: language.labels.authentication.password_reset_success,
            }}
          />
          <button
            className="button"
            style={{ marginTop: "2em" }}
            onClick={() => {
              navigate("/login");
            }}
          >
            {language.labels.authentication.go_to_signin}
          </button>
        </div>
      ) : (
        <form
          className="login-form"
          aria-label={language.labels.aria.form.password_reset}
          onSubmit={(e) => submit(e)}
          autoComplete="off"
          id="code"
        >
          {/* code input */}
          <div
            className="subtext"
            style={{ marginTop: "1.5rem", fontSize: "0.9em", fontWeight: 300 }}
          >
            {language.labels.authentication.code_sms_reset}
          </div>
          <input
            ref={def_focus}
            style={{ marginTop: "0.2rem", width: "100%" }}
            placeholder={language.labels.authentication.code}
            aria-label={language.labels.authentication.code}
            tabIndex={0}
          />
          <div style={{ display: "flex" }}>
            <div
              role="link"
              style={{ fontSize: "0.8em", fontWeight: 300 }}
              className="subtext link"
              onClick={() => setShowModal(true)}
              onKeyUpCapture={(e) => e.key === "Enter" && setShowModal(true)}
              tabIndex="0"
            >
              {language.labels.authentication.code_not_received}
            </div>
          </div>

          {/* password input */}
          <label style={{ paddingTop: "1em", display: "block" }}>
            {language.labels.authentication.password_reset_new}
          </label>
          <div className="password" style={{ width: "100%" }}>
            <input
              ref={pwd_field}
              type={showPassword ? "text" : "password"}
              className="input"
              placeholder={language.labels.authentication.password}
              aria-placeholder={language.labels.authentication.password}
              maxLength={config.string.title}
              autoComplete="one-time-code"
            />
            <div
              role="button"
              aria-label={language.labels.aria.password_show_hide}
              onKeyUpCapture={(e) =>
                e.key === "Enter" &&
                togglePassword(e.target, showPassword, setShowPassword)
              }
              tabIndex={0}
              onClick={(e) =>
                togglePassword(e.target, showPassword, setShowPassword)
              }
            >
              -
            </div>
          </div>
          {passwordErr && (
            <div className="errtext" role="alert">
              {passwordErr}
            </div>
          )}

          {errMsg && (
            <div
              style={{ fontWeight: 600, marginTop: "1rem" }}
              className="errtext"
              role="alert"
            >
              {errMsg}
            </div>
          )}

          <button
            className="button"
            type="submit"
            style={{ width: "100%", marginTop: "2rem", fontSize: "1.1rem" }}
          >
            {language.labels.app.action_submit}
          </button>
        </form>
      )}

      {/* Message Status */}
      <Modal
        className="modal-dynamic"
        show={showModal}
        title={language.labels.authentication.status.sms}
        language={language}
      >
        <div ref={modal_message}></div>
        <div style={{ marginTop: "0.5em" }} ref={modal_notification}></div>

        <div
          style={{
            display: "flex",
            marginTop: "2em",
            gap: "0.25em",
            paddingBottom: "0.5em",
          }}
        >
          <button
            className="button-secondary"
            onClick={() => setShowModal(false)}
          >
            {language.labels.app.ok}
          </button>
          <button ref={modal_button} className="button" onClick={reset}>
            {language.labels.authentication.code_reset}
          </button>
        </div>
      </Modal>
    </>
  );
};
export default CodePage;
