import React, { useState, useEffect, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import config from "../../../../config";
import axiosCall from "../../../../lib/axios";
import Modal from "../../../modal/Modal";

const Preamble = ({ language, animation, next, signout, props }) => {
  //////////// INITIALIZATION ///////////
  const [activeIndex, setActiveIndex] = useState(-1);
  const [extra, setExtras] = useState({});
  const [extraLoaded, setExtraLoaded] = useState(false);
  const [aiModal, setAiModal] = useState(false);
  const [blockExtras, setBlockExtras] = useState(null);
  const [titleErr, setTitleErr] = useState("");
  const [descErr, setDescErr] = useState("");
  const [aiErr, setAiErr] = useState("");
  const [sectionErr, setSectionErr] = useState("");
  const [aiSuccess, setAiSuccess] = useState("");

  const abortAxios = useRef();
  const descRef = useRef();
  const extrasRef = useRef();

  useEffect(() => {
    // def_focus.current.focus()
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("aboutAnimate").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("aboutAnimate").style.animationName =
          "slideout";
        document.getElementById("aboutAnimate").style.animationDirection =
          "reverse";
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("click", clickOff, true);
  }, [extraLoaded]);

  //////////// ERRORS ///////////
  const clearErrors = () => {
    setTitleErr("");
    setDescErr("");
    setSectionErr("");
  };

  const clearAI = () => {
    setAiErr("");
    setAiSuccess("");
  };

  //////////// AI ASSISTANT ///////////
  useEffect(() => {
    //prevent check while animating
    if (!animation.direction && !props.description && props.preamble)
      suggestBite(props.preamble);
  }, [props.preamble]);

  const abort = () => {
    abortAxios.current = 0;
    setAiModal(false);
    if (props.referredBy)
      setAiErr(language.labels.ai.cancelled[props.referredBy]);
  };

  const suggestBite = async (blurb, aboutOnly = true) => {
    clearErrors();
    clearAI();

    // unabort axios call
    const timestamp = Date.now();
    abortAxios.current = timestamp;

    // try and submit data
    setAiModal(true);
    let result = await axiosCall("block/preamble", { preamble: blurb }, false);

    //process result if call not aborted
    if (timestamp === abortAxios.current) {
      if (result.success) {
        setAiModal(false);
        if (
          result.status === 201 ||
          (!result.data.title && !result.data.body)
        ) {
          //either gibberish input or unknown error with AI
          if (props.referredBy) {
            setAiErr(language.labels.ai.nothing[props.referredBy]);
          } else {
            setDescErr(language.labels.ai.nothing.about);
          }
        } else {
          //success
          if (result.data.title && aboutOnly) props.setTitle(result.data.title);

          if (result.data.body) {
            //remove heading in the event that the AI includes it in the body
            let desc = result.data.body.replace(result.data.title + "\n\n", "");
            //limit to chars in event that AI exceeds limit
            props.setDescription(desc.slice(0, config.string.paragraph));
          }

          if (props.referredBy)
            setAiSuccess(language.labels.ai.suggestions[props.referredBy]);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        suggestBite(blurb);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  /////////// EXTRAS  ////////////////
  const getAllExtras = async () => {
    let result = await axiosCall("blocks/extras/all", {
      lang: language.lang,
    });
    if (result.success) {
      setBlockExtras(result.data);
      props.setExtraSections((oldArray) => [
        ...oldArray,
        { heading: "", description: "" },
      ]);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getAllExtras();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  /////////// LOOK FOR SIMILAR BLOCKS BLOCK  ////////////////

  const checkBlock = async (text, about) => {
    //check about
    if (about.length < 150) {
      //insufficient details
      document.getElementById("aboutAnimate").style.animationName = "slideout";
      props.setVague(true);
      props.setEquivalent(false);
      props.setDuplicates([]);
      next({ step: 1, direction: 1 });
    } else {
      // try and submit data
      let result = await axiosCall("block/compare", {
        text,
        pathway: true,
        ignore: props.ignored,
      });
      console.log(result.data);
      if (result.success) {
        document.getElementById("aboutAnimate").style.animationName =
          "slideout";

        //check if equivalent or similar blocks
        if (result.data.equivalent.length + result.data.similar.length > 0) {
          if (result.data.similar.length > 0) {
            props.setDuplicates(result.data.similar);
            props.setEquivalent(false);
          } else {
            props.setDuplicates(result.data.equivalent);
            props.setEquivalent(true);
          }
          props.setBitePathways(result.data.pathways);
          props.setVague(false);
          next({ step: 1, direction: 1 });
        } else {
          next({ step: 2, direction: 1 });
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        checkBlock(text, about);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  //////////// FORM SUBMISSION ///////////
  const submitForm = (e) => {
    e.preventDefault();
    clearErrors();

    if (props.title.trim() === "") {
      setTitleErr(language.labels.bites.error.title);
      document.getElementById("title_field").focus();
      return;
    }

    if (props.description.trim() === "") {
      setDescErr(language.labels.bites.error.description);
      document.getElementById("desc_field").focus();
      return;
    }

    let text = props.title.trim() + " " + props.description.trim();

    if (
      props.extraSections.some(
        (section) =>
          (!section.heading.trim().length &&
            section.description.trim().length) ||
          (section.heading.trim().length && !section.description.trim().length)
      )
    ) {
      setSectionErr(language.labels.bites.error.section);
      //focus on first empty
      var i = 0,
        len = props.extraSections.length;
      while (i < len) {
        if (!props.extraSections[i].heading.trim().length) {
          document.getElementById(`heading_${i}`).focus();
          i = len;
        } else if (!props.extraSections[i].description.trim().length) {
          document.getElementById(`description_${i}`).focus();
          i = len;
        } else {
          i++;
        }
      }
      return;
    }

    var j = 0,
      len = props.extraSections.length;
    while (j < len) {
      text = text + " " + props.extraSections[j].description.trim();
      j++;
    }

    props.setExtraSections(
      props.extraSections.filter(
        (section) =>
          section.heading.trim().length && section.description.trim().length
      )
    );

    checkBlock(text, props.description.trim());
  };

  //////////// MORE DETAILS ///////////
  const addSection = () => {
    if (!blockExtras) {
      getAllExtras();
    } else {
      props.setExtraSections((oldArray) => [
        ...oldArray,
        { heading: "", description: "" },
      ]);
    }
  };

  const changeSection = (index, type, value) => {
    const newSections = [...props.extraSections];
    newSections[index][type] = value;
    props.setExtraSections(newSections);
    if (type === "heading") {
      let results = [];
      for (let i = 0; i < blockExtras.length; i++) {
        let firstchars = value.trim().length;
        if (
          firstchars > 0 &&
          blockExtras[i].extra.substring(0, firstchars).toLowerCase() ===
            value.trim().toLowerCase()
        )
          results.push(blockExtras[i].extra);
      }
      var obj = extra;
      obj[index] = results;
      setActiveIndex(index);
      setExtras((extras) => ({
        ...extras,
        ...obj,
      }));
      setExtraLoaded(!extraLoaded);
    }
  };

  const selectHeading = (index, value) => {
    //move focus to the description
    document.getElementById("description_" + index).focus();

    const newSections = [...props.extraSections];
    newSections[index]["heading"] = value;
    props.setExtraSections(newSections);

    let ex_cpy = { ...extra };
    for (var key in ex_cpy) {
      delete ex_cpy[key];
    }
    setExtras((errors) => ({
      ...ex_cpy,
    }));
  };

  const chkKey = (e, i, n, val) => {
    e.preventDefault();
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      nextResult(e, i, n);
    } else if (e.key === "Enter") {
      selectHeading(n, val);
    }
  };

  const len = (arr) => {
    if (!arr) return 0;
    return arr.length;
  };

  //////////// USER INTERACTIONS ///////////
  const clickOff = (e) => {
    if (
      descRef.current &&
      !descRef.current.contains(e.target) &&
      extrasRef.current &&
      !extrasRef.current.contains(e.target)
    ) {
      let ex_cpy = { ...extra };
      for (var key in ex_cpy) {
        delete ex_cpy[key];
      }
      setExtras((errors) => ({
        ...ex_cpy,
      }));
    }
  };

  const nextResult = (e, i, n) => {
    if (e.key === "ArrowDown") {
      i++;
      if (document.getElementById("SR" + i)) {
        e.preventDefault();
        document.getElementById("SR" + i).focus();
      }
    } else if (e.key === "ArrowUp") {
      i--;
      if (i < 0) {
        document.getElementById(`heading_${n}`).focus();
      } else {
        e.preventDefault();
        document.getElementById("SR" + i).focus();
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  const sections = props.extraSections.map((section, index) => (
    <div style={{ marginTop: "1em" }} key={index}>
      <h3 className="heading3" style={{ fontWeight: "500" }}>
        {language.labels.bites.detail.number.replace(/{num}/g, index + 1)}
      </h3>
      <input
        autoFocus={index === props.extraSections.length - 1 ? true : false}
        id={`heading_${index}`}
        style={{ width: "100%" }}
        value={section.heading}
        onChange={(e) => changeSection(index, "heading", e.target.value)}
        placeholder={language.labels.bites.detail.heading_enter}
        ref={descRef}
        onKeyDown={(e) => {
          nextResult(e, -1, index);
        }}
        maxLength={config.string.title}
      />
      {activeIndex === index && Object.keys(extra).length > 0 && (
        <div style={{ position: "relative" }}>
          <div
            role="list"
            aria-label="search results"
            ref={extrasRef}
            className="tag-search-results"
            style={
              Object.keys(extra).length > 0 && activeIndex > -1
                ? {
                    minHeight: `Calc(1px + ${
                      0.25 + 1.75 * Math.min(28, len(extra[index]))
                    }em)`,
                  }
                : { borderBottom: "none" }
            }
          >
            {extra[activeIndex].map((data, idx) => (
              <div
                role="listitem"
                aria-label={data}
                className="combo-wrap focus-modest"
                key={idx}
                id={`SR${idx}`}
                onMouseOver={(e) => {
                  e.target.focus();
                }}
                onClick={(e) => selectHeading(index, data)}
                onKeyDown={(e) => {
                  chkKey(e, idx, index, data);
                }}
                tabIndex="0"
              >
                <div aria-hidden="true">{data}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <TextareaAutosize
        id={`description_${index}`}
        value={section.description}
        onChange={(e) => changeSection(index, "description", e.target.value)}
        placeholder={language.labels.bites.detail.description_enter}
        aria-placeholder={language.labels.bites.detail.description_enter}
        minRows="5"
        style={{ width: "100%" }}
        maxLength={config.string.paragraph}
      />
    </div>
  ));

  return (
    <>
      <div style={{ position: "relative", maxWidth: "44rem" }}>
        <form
          className="block-step page-section"
          id="aboutAnimate"
          onSubmit={(e) => submitForm(e)}
        >
          {/* about */}
          {!props.referredBy && (
            <div
              style={{ marginBottom: "1em" }}
              dangerouslySetInnerHTML={{ __html: language.labels.bites.about }}
            />
          )}

          {/* preamble */}
          {props.preamble && (
            <div style={{ marginBottom: "1em" }}>
              <label className="text-label">
                {language.labels.bites.creation.context}
              </label>
              <div
                className="font-soft"
                style={{ padding: "0.25rem", fontStyle: "italic" }}
              >
                {props.preamble}
              </div>
            </div>
          )}
          {aiSuccess && (
            <div
              className="success"
              style={{ display: "flex", marginBottom: "1em", gap: "0.25em" }}
            >
              <div className="glyphs">*</div>
              <div style={{ fontSize: "0.9em" }}>{aiSuccess}</div>
            </div>
          )}
          {aiErr && (
            <div
              className="errtext"
              role="alert"
              style={{ marginBottom: "1em", fontWeight: "400" }}
            >
              {aiErr}
            </div>
          )}

          {/* title */}
          <label
            className="text-label"
            htmlFor="title_field"
            style={{ marginTop: "0.5em" }}
          >
            {language.labels.bites.title}
          </label>
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="title_field"
            name="title_field"
            value={props.title}
            placeholder={language.labels.bites.title_enter}
            onChange={(e) => props.setTitle(e.target.value)}
            maxLength={config.string.title}
          />
          {titleErr && (
            <div className="errtext" role="alert">
              {titleErr}
            </div>
          )}

          {/* description */}
          <label
            className="text-label"
            htmlFor="desc_field"
            style={{ marginTop: "1em" }}
          >
            {language.labels.bites.description.about}
          </label>
          <TextareaAutosize
            id="desc_field"
            value={props.description}
            onChange={(e) => {
              props.setDescription(e.target.value);
            }}
            placeholder={language.labels.bites.description.enter}
            aria-placeholder={language.labels.bites.description.enter}
            minRows="10"
            style={{ width: "100%" }}
            maxLength={config.string.paragraph}
          />
          {descErr && (
            <div className="errtext" role="alert">
              {descErr}
            </div>
          )}
          {props.title && (
            <div
              className="link"
              style={{ marginTop: "0.5em", fontSize: "0.9em" }}
              tabIndex={0}
              onClick={() => {
                suggestBite(props.title, false);
              }}
              onKeyUp={(e) =>
                (e.key === "Enter" || e.key === " ") &&
                suggestBite(props.title, false)
              }
            >
              {language.labels.ai.suggestions.bite}
            </div>
          )}

          {/* more details */}
          <h2
            className="heading2"
            style={{
              margin: "1.5em 0 0.5em",
              fontWeight: "500",
              fontSize: "1.1em",
            }}
          >
            {language.labels.bites.more_details}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: language.labels.bites.detail.subheader,
            }}
            style={{ fontSize: "0.9em" }}
          />
          {sections}
          {sectionErr && (
            <div className="errtext" role="alert">
              {sectionErr}
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginTop: "0.5em",
              fontSize: "0.9em",
            }}
          >
            {props.extraSections.length < config.max_block_details ? (
              <div
                className="link"
                tabIndex={0}
                onClick={addSection}
                onKeyUp={(e) =>
                  (e.key === "Enter" || e.key === " ") && addSection()
                }
              >
                {language.labels.bites.detail.add}
              </div>
            ) : (
              <div>{language.labels.bites.detail.limit}</div>
            )}
          </div>

          {/* buttons */}
          <div style={{ display: "flex", margin: "1.5em 0" }}>
            <button className="button" type="submit">
              {language.labels.app.action_next}
            </button>
          </div>
        </form>
      </div>

      {/* AI Modal */}
      <Modal className="modal-dynamic" show={aiModal}>
        <div style={{ margin: "1em 0" }}>
          <h2 className="heading">{language.labels.loader.wait}</h2>
          <div style={{ fontSize: "1.1em", padding: "0.5rem" }}>
            {language.labels.ai.moment}
          </div>
          <div
            className="dotdotdot"
            style={{ fontSize: "1.1em", padding: "0.5rem" }}
          >
            {props.referredBy
              ? language.labels.ai.context
              : language.labels.ai.bite}
          </div>
          <div style={{ display: "flex", marginTop: "1em" }}>
            <button className="button-off" onClick={() => abort()}>
              {language.labels.ai.cancel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Preamble;
