const Card = async (params) =>{
	//work around youtube and tiktok
	let simple = false
	let link = params.link
	if(link.indexOf('youtube.com') > 0){
		simple = true
	}
	else if(link.indexOf('youtu.be') > 0){
		simple = true
	}
	else if(link.indexOf('google.com') > 0){
		simple = true
	}
	else if(link.indexOf('tiktok.com') > 0){
		simple = true
	}

	if(simple){
		return "<a href=" + link + " target='_new'>" + link + "</a>"
	} else {
		//initialize
		let image = "<div class='preview-icon' aria-hidden=true>y</div>"
		let title = ""
		let description = ""

		//set values
		if(params.images && params.images.length > 0){
			let n = 0
			if(params.images.length > 1) n = 1
			image = "<div class='preview-img'><img src='"+params.images[n]+"' height='84' width='auto' alt='"+link+"' /></div>"
		}

		if(params.title) title = "<div class='clamped one preview-card-title'>"+params.title+"</div>"
		if(params.siteName) title = "<div class='clamped one preview-card-title'>"+params.siteName+"</div>"
		if(params.description) description = "<div class='clamped two' style='font-size:0.8em'>"+params.description+"</div>"


		//return card html
		let card = "<div class='preview-card'>" + image + "<div class='preview-txt'>" + title + description
		card += "<a href=" + link + " target='_new' class='clamped one' style='font-size: 0.9em; margin-top:0.2em'>" + link + "</a></div></div>"

		return card
	}

}

export default Card
