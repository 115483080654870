import { React, useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";
import { parsePhoneNumber } from "react-phone-number-input";

import config from "../../../../config";
import countries from "../../../../countries";
import { togglePassword } from "../../../../lib/common";
import validator from "../../../../lib/validation";

const PhoneForm = ({
  language,
  animation,
  next,
  def_focus,
  cookies,
  username,
  setPhoneNumber,
  setEmail,
  password,
  setPassword,
  setMessageResponse,
}) => {
  /////////////// INITIALIZE ///////////////
  const [localNumber, setLocalNumber] = useState();
  const [iso, setIso] = useState({});
  const [country, setCountry] = useState(false);
  const [useMail, setUseMail] = useState(true);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [international, setInternational] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [mailError, setMailError] = useState("");
  const [pwrdError, setPwrdError] = useState("");
  const [termsError, setTermsError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errReserved, setErrReserved] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (cookies.get("country") && cookies.get("uuid")) {
      countryIso(cookies.get("country"));
      if (animation && animation.step === 1) {
        if (animation.direction === 1) {
          document.getElementById("phone").style.animationName = "slidein";
        } else if (animation.direction === -1) {
          document.getElementById("phone").style.animationName = "slideout";
          document.getElementById("phone").style.animationDirection = "reverse";
        }
      }
    } else {
      window.location.reload();
    }
  }, []);

  const countryIso = async (country) => {
    if (country === "-") {
      setUseMail(false);
    } else {
      for (var i = 0; i < countries.calling_codes.length; i++) {
        if (countries.calling_codes[i].country == country) {
          setIso(countries.calling_codes[i]);
          setUseMail(false);
          setCountry(true);
          break;
        }
      }
    }
  };

  /////////////// PROCESS FORM ///////////////
  const nextStep = async (e) => {
    e.preventDefault();

    //reset error messages
    setMailError("");
    setPhoneError("");
    setPwrdError("");
    setTermsError("");
    setErrorMsg("");
    setErrReserved(false);

    try {
      //init post data
      let post_data = { uuid: cookies.get("uuid"), language: language.lang };

      if (useMail) {
        //check email format
        setPhoneNumber("");
        let email = def_focus.current.value.toLowerCase().trim();
        var email_chk = validator.emailValidator(email);
        if (!email_chk) {
          document.getElementById("email_field").focus();
          setMailError(language.labels.authentication.email_invalid);
          return;
        }
        setEmail(email);
        post_data.email = email;
        post_data.country = cookies.get("country");
      } else {
        //validate phone number
        setEmail("");
        if (!localNumber) {
          def_focus.current.focus();
          setPhoneError(language.labels.authentication.phone_invalid);
          return;
        }
        let _phone = parsePhoneNumber(localNumber);
        if (_phone?.country) {
          let country_obj = await validator.countryCaller(
            _phone.country,
            countries.calling_codes
          );
          if (country_obj.country) {
            setPhoneNumber(_phone.number);
            post_data.phone = _phone.number;
            post_data.country = _phone.country;
          } else {
            setPhoneError(
              language.labels.authentication.phone_unsupported.replace(
                /{caller}/g,
                _phone.countryCallingCode
              )
            );
            return;
          }
        } else {
          setPhoneError(language.labels.authentication.phone_invalid);
          return;
        }
      }

      //check password
      if (password) {
        var pwd_chk = validator.passwordValidator(password);
        if (!pwd_chk) {
          document.getElementById("password_field").focus();
          setPwrdError(language.labels.authentication.password_invalid);
          return;
        }
      } else {
        document.getElementById("password_field").focus();
        return;
      }

      //check agree terms
      if (!agreeTerms) {
        document.getElementById("agree_tc_checkbox").focus();
        setTermsError(language.labels.error.terms);
        return;
      }

      //register the device
      document.getElementById("loader").style.display = "inline";
      setErrorMsg(language.labels.app.action_sending);
      const configurationObject = {
        url: `${config.server.api}/auth/sendcode`,
        method: "POST",
        data: post_data,
      };
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        //go to next step
        setMessageResponse(response.data);
        document.getElementById("phone").style.animationName = "slideout";
        next({ step: 2, direction: 1 });
      } else {
        setErrorMsg(language.labels.error[response.data.message]);
        if (
          response.data.message === "phone_taken" ||
          response.data.message === "email_taken"
        )
          setErrReserved(true);
      }
    } catch (err) {
      document.getElementById("loader").style.display = "";
      setErrorMsg(language.labels.error.unknown);
    }
  };

  const startOver = () => {
    document.getElementById("phone").style.animationName = "slidein";
    document.getElementById("phone").style.animationDirection = "reverse";
    next({ step: 0, direction: -1 });
  };

  /////////////// RENDER GUI ///////////////
  return (
    <form
      className="signup"
      id="phone"
      onSubmit={(e) => nextStep(e)}
      aria-label={language.labels.aria.form.signup}
      autoComplete="off"
    >
      {/* display username */}
      {username && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{language.labels.authentication.username}</div>
          <div style={{ minWidth: "1rem" }}></div>
          <div className="quote-text" style={{ fontSize: "1.1em" }}>
            {username}
          </div>
        </div>
      )}

      {/* switch registration method */}
      <div
        style={{
          marginTop: "1.5rem",
          display: "flex",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div>
          {useMail
            ? language.labels.authentication.register_mail
            : language.labels.authentication.register_phone}
        </div>
        <div
          style={{ fontSize: "0.9em" }}
          role="button"
          className="link"
          onKeyUpCapture={(e) => e.key === "Enter" && setUseMail(!useMail)}
          tabIndex={0}
          onClick={() => setUseMail(!useMail)}
        >
          {useMail
            ? language.labels.authentication.switch_to_phone
            : language.labels.authentication.switch_to_mail}
        </div>
      </div>

      <label
        htmlFor={useMail ? "email_field" : "phone_field"}
        aria-hidden="true"
        style={{ marginTop: "1.5rem", fontWeight: 500, display: "block" }}
      >
        {useMail
          ? language.labels.authentication.email_address
          : iso.code && !international
          ? language.labels.authentication.phone_number
          : language.labels.authentication.phone_caller}
      </label>

      {useMail ? (
        // email input
        <>
          <input
            ref={def_focus}
            id="email_field"
            style={{ width: "100%" }}
            placeholder={language.labels.authentication.email_enter_your}
            aria-placeholder={language.labels.authentication.email_enter_your}
            maxLength={config.string.email}
          />
          {mailError && (
            <div className="errtext" role="alert">
              {mailError}
            </div>
          )}
          <div
            style={{ fontSize: "0.9em", fontWeight: 300, padding: "0.1em 0" }}
          >
            {language.labels.authentication.code_mail}
          </div>
        </>
      ) : (
        // phone input
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {country && !international && (
              <div aria-label={iso.country + " " + iso.code}>
                {iso.flag + " " + iso.code}
              </div>
            )}
            {country && !international ? (
              <PhoneInput
                ref={def_focus}
                international={true}
                country={iso.country}
                placeholder={language.labels.authentication.phone_enter_your}
                value={localNumber}
                onChange={setLocalNumber}
              />
            ) : (
              <PhoneInput
                ref={def_focus}
                international={true}
                placeholder={language.labels.authentication.phone_enter_your}
                value={localNumber}
                onChange={setLocalNumber}
              />
            )}
          </div>
          {phoneError && (
            <div className="errtext" role="alert">
              {phoneError}
            </div>
          )}
          {(localNumber || international || !country) && (
            <div
              style={{ fontSize: "0.9em", fontWeight: 300, padding: "0.1em 0" }}
            >
              {language.labels.authentication.code_sms}
            </div>
          )}
          {!localNumber && country && !international && (
            <div
              role="link"
              className="link"
              style={{ fontSize: "0.9em", fontWeight: 300, padding: "0.1em 0" }}
              onClick={() => setInternational(true)}
              onKeyUpCapture={(e) =>
                e.key === "Enter" && setInternational(true)
              }
              tabIndex="0"
            >
              {language.labels.authentication.wrong_caller.replace(
                /{caller}/g,
                iso.code
              )}
            </div>
          )}
        </>
      )}

      <label
        htmlFor="password_field"
        aria-hidden="true"
        style={{ marginTop: "2rem", fontWeight: 500, display: "block" }}
      >
        {language.labels.authentication.password}
      </label>
      <div className="password" style={{ width: "100%" }}>
        <input
          id="password_field"
          placeholder={language.labels.authentication.password_create}
          aria-placeholder={language.labels.authentication.password_create}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          type={showPassword ? "text" : "password"}
          maxLength={config.string.title}
          autoComplete="one-time-code"
        />
        <div
          role="button"
          aria-label={language.labels.aria.password_show_hide}
          onKeyUpCapture={(e) =>
            e.key === "Enter" &&
            togglePassword(e.target, showPassword, setShowPassword)
          }
          tabIndex={0}
          onClick={(e) =>
            togglePassword(e.target, showPassword, setShowPassword)
          }
        >
          -
        </div>
      </div>
      {pwrdError && (
        <div className="errtext" role="alert">
          {pwrdError}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          fontSize: ".9rem",
          marginTop: "1rem",
        }}
      >
        <input
          id="agree_tc_checkbox"
          type={"checkbox"}
          onChange={(e) => setAgreeTerms(e.target.checked)}
          defaultChecked={agreeTerms}
        />
        <div style={{ minWidth: "0.5rem" }}></div>
        <label htmlFor="agree_tc_checkbox">
          {language.labels.authentication.terms_agreement}
        </label>
      </div>
      {termsError && (
        <div
          style={{ fontWeight: 600, padding: ".2em 0 0 0" }}
          className="errtext"
          role="alert"
        >
          {termsError}
        </div>
      )}
      {errorMsg && (
        <div
          style={{ fontWeight: 600, padding: "1.5em 0 0 0" }}
          className="errtext"
          role="alert"
        >
          {errorMsg}
        </div>
      )}
      {errReserved && (
        <div
          style={{ fontSize: "0.7em", fontWeight: 300, marginTop: "0.5em" }}
          role="alert"
        >
          {language.labels.error.reservations}
        </div>
      )}
      <button
        className="button"
        type="submit"
        style={{ width: "100%", marginTop: "1.5rem", fontSize: "1.1rem" }}
      >
        {language.labels.app.action_next}
      </button>
      <div className="join-container">
        <div
          role="link"
          className="link"
          style={{ fontSize: "0.8em", marginTop: "1em" }}
          onClick={() => startOver()}
          onKeyUpCapture={(e) =>
            (e.key === "Enter" || e.key === " ") && startOver()
          }
          tabIndex={0}
        >
          {language.labels.authentication.back_first}
        </div>
      </div>
    </form>
  );
};

export default PhoneForm;
