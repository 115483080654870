import axios from "axios";
import config from "../config";
import encryption from "./encryption";

const refreshToken = async () => {
  let cid = localStorage.getItem("client_id");
  const configurationObject = {
    url: `${config.server.api}/refreshtoken/hub/${cid}`,
    method: "GET",
    withCredentials: true,
    credentials: "include",
  };
  let res = await axios(configurationObject);
  if (res.status === 200) {
    //successfully refreshed token, update time and tag
    localStorage.setItem("last_updated", Date.now());
    localStorage.setItem("tag", encryption.encrypt(res.data.jwt));
    return true;
  } else {
    return false;
  }
};

export default refreshToken;
