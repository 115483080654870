import { React, useState, useEffect, useRef } from "react";
import { getIPLocation } from "react-ip-location";
import PhoneInput from "react-phone-number-input/input";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

import validator from "../../../../lib/validation";
import config from "../../../../config";
import countries from "../../../../countries";

const Initiate = ({
  language,
  animation,
  next,
  def_focus,
  page,
  setRegistrations,
  setEmail,
  setPhone,
  setUserName,
}) => {
  ////////////////// INITIALIZE //////////////////
  const cookies = new Cookies();
  const mail_focus = useRef();

  const [errMsg, setErrMsg] = useState("");
  const [idErr, setIdErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [tooMany, setTooMany] = useState(false);
  const [localNumber, setLocalNumber] = useState();
  const [iso, setIso] = useState({});
  const [country, setCountry] = useState(false);
  const [international, setInternational] = useState(false);

  useEffect(() => {
    //country
    if (page === "username") getCountry();

    //def focus
    if (def_focus.current) {
      clearErrors();
      def_focus.current.value = "";
      def_focus.current.focus();
    }

    //animation
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("initiate").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("initiate").style.animationName = "slideout";
        document.getElementById("initiate").style.animationDirection =
          "reverse";
      }
    }
  }, [page]);

  ////////////////// FIX COUNTRY //////////////////
  const getCountry = async () => {
    let country = cookies.get("country");
    if (!country) {
      document.getElementById("loader").style.display = "inline";
      try {
        const location = await getIPLocation();
        cookies.set("country", location.country);
        countryIso(location.country);
      } catch (err) {
        cookies.set("country", "-");
        document.getElementById("loader").style.display = "none";
      }
    } else {
      countryIso(cookies.get("country"));
    }
  };

  const countryIso = async (country) => {
    for (var i = 0; i < countries.calling_codes.length; i++) {
      if (countries.calling_codes[i].country == country) {
        setIso(countries.calling_codes[i]);
        setCountry(true);
        document.getElementById("loader").style.display = "none";
        break;
      }
    }
  };

  ////////////////// FIX INPUTS //////////////////
  const clearErrors = () => {
    setErrMsg("");
    setIdErr("");
    setEmailErr("");
    setPhoneErr("");
  };

  useEffect(() => {
    if (localNumber) {
      mail_focus.current.value = "";
      setEmailErr("");
    }
  }, [localNumber]);

  const fixEmail = () => {
    // def_focus.current.value = "";
    setLocalNumber("");
    setPhoneErr("");
  };

  ////////////////// SUBMIT FORM //////////////////
  const nextStep = async (e) => {
    e.preventDefault();

    //reset error messages
    clearErrors("");

    let recovery;
    if (cookies.get("recovery")) {
      recovery = cookies.get("recovery");
    } else {
      recovery = uuidv4();
      cookies.set("recovery", recovery);
    }
    let data = { recovery };
    let api;

    try {
      if (page === "password") {
        api = "/recover/registration";
        if (!def_focus.current.value.toLowerCase().trim()) return;
        data.username = def_focus.current.value.toLowerCase().trim();
        setUserName(def_focus.current.value.toLowerCase().trim());
      } else {
        api = "/recover/id";
        if (localNumber) {
          //validate phone
          let _phone = parsePhoneNumber(localNumber);
          if (_phone?.country) {
            let country_obj = await validator.countryCaller(
              _phone.country,
              countries.calling_codes
            );
            if (country_obj.country) {
              setPhone(_phone.number);
              data.phone = _phone.number;
            } else {
              setPhoneErr(language.labels.authentication.phone_invalid);
              return;
            }
          } else {
            setPhoneErr(language.labels.authentication.phone_invalid);
            return;
          }
        } else if (mail_focus.current.value) {
          //validate email
          let reg = mail_focus.current.value.toLowerCase().trim();
          if (validator.emailValidator(reg)) {
            setEmail(reg);
            data.email = reg;
            data.language = language.lang;
          } else {
            setEmailErr(language.labels.authentication.email_invalid);
            return;
          }
        } else {
          setPhoneErr(language.labels.authentication.phone_invalid);
          return;
        }
      }

      const configurationObject = {
        url: config.server.api + api,
        method: "POST",
        data: data,
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      console.log(configurationObject);

      if (response.status === 200) {
        //go to next step
        document.getElementById("initiate").style.animationName = "slideout";
        if (page === "password") {
          setRegistrations(response.data);
          next({ step: 1, direction: 1 });
        } else {
          next({ step: 2, direction: 1 });
        }
      } else if (response.status === 201) {
        setErrMsg(language.labels.error[response.data.message]);
      } else if (response.status === 202) {
        setErrMsg(language.labels.authentication.too_many[page]);
        setTooMany(true);
      } else {
        setErrMsg(language.labels.error.unknown);
      }
    } catch (err) {
      setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
    }
  };

  ////////////////// RENDER GUI //////////////////
  return (
    <form
      className="signup"
      id="initiate"
      onSubmit={(e) => nextStep(e)}
      aria-label={language.labels.aria.form[page]}
      autoComplete="off"
    >
      {page === "password" ? (
        <>
          <label htmlFor="username_field" aria-hidden="true">
            {language.labels.authentication.username}
          </label>
          <input
            ref={def_focus}
            style={{ width: "100%" }}
            placeholder={language.labels.authentication.username_enter}
            aria-placeholder={language.labels.authentication.username_enter}
            autoComplete="one-time-code"
          />
          {idErr && (
            <div
              className="errtext"
              role="alert"
              style={{ marginBottom: "0.5em" }}
            >
              {idErr}
            </div>
          )}
        </>
      ) : (
        <>
          {/* enter phone */}
          <label>
            {iso.code && !international
              ? language.labels.authentication.phone_number
              : language.labels.authentication.phone_caller}
          </label>
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            {country && !international && (
              <div aria-label={iso.country + " " + iso.code}>
                {iso.flag + " " + iso.code}
              </div>
            )}
            {country && !international ? (
              <PhoneInput
                ref={def_focus}
                international={true}
                country={iso.country}
                placeholder={language.labels.authentication.phone_enter_your}
                value={localNumber}
                onChange={setLocalNumber}
              />
            ) : (
              <PhoneInput
                ref={def_focus}
                international={true}
                placeholder={language.labels.authentication.phone_enter_your}
                value={localNumber}
                onChange={setLocalNumber}
              />
            )}
          </div>
          {phoneErr && (
            <div
              style={{ fontWeight: 600, margin: "0.25em 0" }}
              className="errtext"
              role="alert"
            >
              {phoneErr}
            </div>
          )}
          {(localNumber || international || !country) && (
            <div
              style={{ fontSize: "0.9em", fontWeight: 300, padding: "0.1em 0" }}
            >
              {language.labels.authentication.code_sms}
            </div>
          )}
          {!localNumber && country && !international && (
            <div
              role="link"
              className="link"
              style={{ fontSize: "0.9em", fontWeight: 300, padding: "0.1em 0" }}
              onClick={() => setInternational(true)}
              onKeyUpCapture={(e) =>
                e.key === "Enter" && setInternational(true)
              }
              tabIndex="0"
            >
              {language.labels.authentication.wrong_caller.replace(
                /{caller}/g,
                iso.code
              )}
            </div>
          )}

          <div style={{ margin: "1em 0" }}>{language.labels.app.or}</div>

          <label htmlFor="email_field" aria-hidden="true">
            {language.labels.authentication.email_address}
          </label>
          <input
            ref={mail_focus}
            style={{ width: "100%" }}
            placeholder={language.labels.authentication.email_enter_your}
            aria-placeholder={language.labels.authentication.email_enter_your}
            onChange={fixEmail}
            autoComplete="one-time-code"
          />
          {emailErr && (
            <div
              style={{ fontWeight: 600, marginTop: "0.25em" }}
              className="errtext"
              role="alert"
            >
              {emailErr}
            </div>
          )}
        </>
      )}

      {errMsg && (
        <div className="errtext" role="alert" style={{ marginTop: "1rem" }}>
          {errMsg}
        </div>
      )}

      {tooMany && (
        <div style={{ marginTop: "1rem", fontSize: "0.8em", fontWeight: 300 }}>
          {language.labels.authentication.too_many.explained}
        </div>
      )}

      <button
        className="button"
        type="submit"
        style={{ width: "100%", marginTop: "2rem", fontSize: "1.1em" }}
      >
        {language.labels.app.action_next}
      </button>
    </form>
  );
};

export default Initiate;
