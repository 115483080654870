import { React, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GetApp, PubBar, PubFooter, PubNav } from "../widgets";

const screen = require("../../media/yongo.png");
const video_source = require("../../media/yongo.mp4");

const Yongo = ({ language, os }) => {
  ////////////// RENDER GUI //////////////
  const navigate = useNavigate();
  const auth = useSelector((state) => state.authentication.value);
  const [videoPlaying, setVideoPlaying] = useState(true);
  const video_ref = useRef();

  const replayVideo = () => {
    if (video_ref.current.firstChild.currentTime > 41) {
      setVideoPlaying(true);
      video_ref.current.firstChild.currentTime = 0;
      video_ref.current.firstChild.play();
    }
  };

  return (
    <main>
      <PubBar language={language} buttons={true} />
      {/* <PubNav language={language} selected={"yongo"} /> */}

      <div
        className="public section"
        style={{ paddingBottom: "6em", paddingTop: "2em" }}
      >
        {/* available */}
        <div
          style={{
            fontSize: "1.3em",
            textAlign: "center",
            fontWeight: 400,
            paddingBottom: "1em",
          }}
        >
          {language.labels.brand.available[os]}
        </div>

        <GetApp os={os} myclass={"public-center"} language={language} />

        {/* intro */}
        <div
          className="mobile-center"
          style={{ fontSize: "1.1em", paddingTop: "2em" }}
        >
          {language.labels.brand.yongo}
        </div>

        {/* video */}
        <div
          aria-hidden={true}
          ref={video_ref}
          style={{ margin: "2em 0 1em", textAlign: "center" }}
        >
          <video
            className={videoPlaying ? "" : "hover"}
            muted={true}
            autoPlay={true}
            poster={screen}
            onEnded={() => setVideoPlaying(false)}
            onPlay={() => setVideoPlaying(true)}
            style={{ maxWidth: "720px", borderRadius: "6px" }}
            onClick={replayVideo}
          >
            <source src={video_source} type="video/mp4" />
          </video>
        </div>

        {/* blurb */}
        {language.labels.brand.blurb.map((paragraph, index) => (
          <div
            key={index}
            className="mobile-center"
            style={{ marginTop: "1em" }}
          >
            {paragraph}
          </div>
        ))}

        {/* Start Now */}
        <div style={{ marginTop: "2em", textAlign: "center" }}>
          {auth.account_type &&
          auth.account_type !== "suspended" &&
          auth.agree_terms === "ok" ? (
            <button className="link" onClick={() => navigate("/")}>
              {language.labels.public.use_now}
            </button>
          ) : (
            <button className="button" onClick={() => navigate("/signup")}>
              {language.labels.authentication.signup.join}
            </button>
          )}
        </div>
      </div>

      <PubFooter language={language} />
    </main>
  );
};

export default Yongo;
