import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";

import { setCommunities } from "../../../features/communities";
import { setContext } from "../../../features/context";

import config from "../../../config";
import axiosCall from "../../../lib/axios";
import validator from "../../../lib/validation";

const CommunityBasic = React.forwardRef(
  ({ language, resetCrest, signout }, ref) => {
    ///////////////// INITIALIZE /////////////////
    const community = useSelector((state) => state.context.value);
    const communities = useSelector((state) => state.communities.value);

    const dispatch = useDispatch();

    const [title, setTitle] = useState(community.title);
    const [description, setDesc] = useState(community.description);
    const [website, setWebsite] = useState(community.website || "");
    const [errWebsite, setErrWebsite] = useState("");

    ////////////// EDIT BASIC DETAILS //////////////
    const changeBasic = async (e, change = true) => {
      setErrWebsite("");
      let val = e.target.getAttribute("name");
      let keyed = null;
      if (e.key) keyed = val;

      //check for changes
      if (
        !change ||
        (val === "title_field" &&
          (title.trim() === community.title || title.trim() === "")) ||
        (val === "description_field" &&
          (description.trim() === community.description ||
            description.trim() === "")) ||
        (val === "website_field" &&
          (website.trim() === community.website || website.trim() === ""))
      ) {
        //just reset and close editor
        setBlur(val, true, keyed);
        return;
      }

      //init post data
      let data = { cid: community.id };

      if (val === "website_field") {
        //check valid url
        const url = validator.URLchecker(website);
        if (url.protocol === "https:") {
          data.website = url.href;
        } else {
          setErrWebsite(language.labels.communities.website_error);
          return;
        }
      } else {
        data.title = title.trim();
        data.description = description.trim();
      }

      // try and submit data
      let result = await axiosCall("community/change", data);

      if (result.success) {
        let _community = { ...community };
        if (data.website) {
          _community.website = data.website;
        } else {
          _community.title = data.title;
          _community.description = data.description;
        }

        // set context
        dispatch(setContext(_community));
        localStorage.setItem("context", JSON.stringify(_community));

        // set communities
        const _communities = await resetCommunities(_community);
        dispatch(setCommunities(_communities));
        localStorage.setItem("communities", JSON.stringify(_communities));

        setBlur(val, true, keyed);
      } else if (result.refresh) {
        //token has been refreshed, try again
        changeBasic(e);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    };

    const removeWebsite = async () => {
      // try and submit data
      let result = await axiosCall("website/unset", {
        cid: community.id,
      });
      if (result.success) {
        setWebsite("");
        let _community = { ...community };
        if (_community.website) {
          delete _community.website;

          // set context
          dispatch(setContext(_community));
          localStorage.setItem("context", JSON.stringify(_community));

          // set communities
          const _communities = await resetCommunities(_community);
          dispatch(setCommunities(_communities));
          localStorage.setItem("communities", JSON.stringify(_communities));
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        removeWebsite();
      } else {
        //refresh token expired or unknown error
        // signout();
      }
    };

    async function resetCommunities(obj) {
      let _communities = [...communities];
      for (var i = 0; i < _communities.length; i++) {
        if (_communities[i].id === community.id) {
          _communities[i] = obj;
          return _communities;
        }
      }
    }

    ////////////// FOCUS //////////////
    const clearFocus = (excluding = "") => {
      resetCrest();
      if (excluding !== "title_field") setTitle(community.title);
      if (excluding !== "description_field") setDesc(community.description);
      if (excluding !== "website_field") setWebsite(community.website || "");
    };
    const setBlur = (name, reblur = false, keyed = null) => {
      //timeout workaround to allow time for focus to shift
      setTimeout(() => {
        if (reblur) {
          document.activeElement.blur();
        } else if (name !== document.activeElement.getAttribute("name")) {
          switch (name) {
            case "title_field":
              setTitle(community.title);
              break;
            case "description_field":
              setDesc(community.description);
              break;
            case "website_field":
              setWebsite(community.website || "");
              break;
          }
        }
        if (keyed) {
          //set focus to next field
          if (keyed === "title_field") {
            document.getElementById("description_field").focus();
          } else if (keyed === "description_field") {
            document.getElementById("website_field").focus();
          } else {
            ref.current.focus();
          }
        }
      }, 100);
    };

    ///////////////// RENDER GUI /////////////////
    return (
      <div
        className="page-section mobile-center"
        style={{ marginTop: "0.5em" }}
      >
        {/* title */}
        <label className="text-label" htmlFor="title_field">
          {language.labels.communities.community_name}
        </label>
        <div
          className="natural-edit"
          style={{ display: "flex", maxWidth: "44rem" }}
        >
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="title_field"
            name="title_field"
            value={title}
            placeholder={language.labels.communities.community_name}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={() => clearFocus("title_field")}
            onBlur={() => setBlur("title_field")}
            onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
            maxLength={config.string.title}
          />
          <div
            style={{
              flexBasis: "3.5rem",
              display: "flex",
              justifyContent: "space-around",
              margin: "-0.25rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="title_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("title_field")}
              onClick={(e) => changeBasic(e, "title_field")}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="title_field"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("title_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>

        {/* description */}
        <label
          style={{ display: "block", marginTop: "1em" }}
          className="text-label"
          htmlFor="description_field"
        >
          {language.labels.communities.community_description}
        </label>
        <div
          className="natural-edit"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "66rem",
          }}
        >
          <TextareaAutosize
            minRows={4}
            style={{ width: "100%" }}
            id="description_field"
            name="description_field"
            value={description}
            onChange={(e) => setDesc(e.target.value)}
            onFocus={() => clearFocus("description_field")}
            onBlur={() => setBlur("description_field")}
            placeholder={language.labels.communities.community_description}
            maxLength={config.string.paragraph}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 0,
              margin: "0 -0.5rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="description_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("description_field")}
              onClick={(e) => {
                changeBasic(e);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="description_field"
              style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("description_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>

        {/* website */}
        <label
          style={{ display: "block", marginTop: "1em" }}
          className="text-label"
          htmlFor="website_field"
        >
          {language.labels.communities.website}
        </label>
        <div
          className="natural-edit"
          style={{ display: "flex", maxWidth: "44rem" }}
        >
          <input
            autoComplete="off"
            style={{ flex: 1 }}
            id="website_field"
            name="website_field"
            value={website}
            placeholder={language.labels.communities.website}
            onChange={(e) => setWebsite(e.target.value)}
            onFocus={() => clearFocus("website_field")}
            onBlur={() => setBlur("website_field")}
            onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
            maxLength={config.string.title}
          />
          <div
            style={{
              flexBasis: "3.5rem",
              display: "flex",
              justifyContent: "space-around",
              margin: "-0.25rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name="website_field"
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={() => setBlur("website_field")}
              onClick={(e) => changeBasic(e, "website_field")}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name="website_field"
              style={{ fontSize: "0.7rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={() => setBlur("website_field")}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
        {errWebsite && (
          <div className="errtext" role="alert">
            {errWebsite}
          </div>
        )}
        {community.website && (
          <button
            className="link"
            style={{
              display: "inline-block",
              fontSize: ".8rem",
              padding: "0 .3rem",
            }}
            onClick={() => removeWebsite()}
          >
            {language.labels.communities.website_remove}
          </button>
        )}
      </div>
    );
  }
);
export default CommunityBasic;
