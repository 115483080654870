import { React, useState, useEffect } from "react";
import Modal from "../modal/Modal";
import { PubBar } from "../widgets";

const Support = ({ language, checkPolicies }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalHTML, setModalHTML] = useState("");
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    if (document.getElementById("modal_body")) {
      document.getElementById("modal_body").innerHTML = modalHTML;
    }
  }, [modalHTML]);

  const displayPolicy = async (documents, doc) => {
    var i = 0,
      len = documents.length;
    while (i < len) {
      if (documents[i].policy === doc) {
        setModalTitle(documents[i].title);
        setModalHTML(documents[i].html);
        setDisplayModal(true);
        break;
      }
      i++;
    }
  };

  const showPolicy = async (doc) => {
    if (policies) {
      displayPolicy(policies, doc);
    } else {
      const _policies = await checkPolicies();
      setPolicies(_policies);
      displayPolicy(_policies, doc);
    }
  };

  const closeModal = () => {
    setDisplayModal(false);
    setModalHTML("");
  };
  return (
    <main>
      <PubBar language={language} buttons={true} />

      <div className="public section" style={{ paddingBottom: "2em" }}>
        <h2>Support</h2>
        <div
          dangerouslySetInnerHTML={{ __html: language.labels.app.report_issue }}
        />
        <div style={{ marginTop: "1em" }}>{language.labels.app.report_app}</div>
        <hr style={{ marginTop: "1.5em" }}></hr>
        <div
          style={{
            display: "flex",
            marginTop: "1.5em",
            alignItems: "center",
          }}
        >
          <div
            className="subheading-icon hover"
            style={{ fontSize: "1.2em" }}
            onClick={() => {
              showPolicy("PP");
            }}
          >
            S
          </div>
          <button
            className="hover"
            onClick={() => {
              showPolicy("PP");
            }}
          >
            {language.labels.app.privacy_policy}
          </button>
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "1.5em",
            alignItems: "center",
          }}
        >
          <div
            className="subheading-icon hover"
            style={{ fontSize: "1.2em" }}
            onClick={() => {
              showPolicy("TC");
            }}
          >
            t
          </div>
          <button
            className="hover"
            onClick={() => {
              showPolicy("TC");
            }}
          >
            {language.labels.app.terms_conditions}
          </button>
        </div>
      </div>
      <Modal
        className="modal-dynamic"
        title={modalTitle}
        show={displayModal}
        language={language}
      >
        <section
          className="focus-modest"
          id="modal_body"
          aria-label={modalTitle}
          tabIndex={0}
        ></section>
        <div style={{ display: "flex", paddingBottom: "0.5em" }}>
          <button className="button-on" onClick={closeModal}>
            {language.labels.app.action_close}
          </button>
        </div>
      </Modal>
    </main>
  );
};

export default Support;
