import { React, useState, useEffect, forwardRef } from "react";
import Modal from "../modal/Modal";

const Footer = forwardRef(({ language, checkPolicies }, ref) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalHTML, setModalHTML] = useState("");
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    if (document.getElementById("modal_body")) {
      document.getElementById("modal_body").innerHTML = modalHTML;
    }
  }, [modalHTML]);

  const displayPolicy = async (documents, doc) => {
    var i = 0,
      len = documents.length;
    while (i < len) {
      if (documents[i].policy === doc) {
        setModalTitle(documents[i].title);
        setModalHTML(documents[i].html);
        setDisplayModal(true);
        break;
      }
      i++;
    }
  };

  const showPolicy = async (doc) => {
    if (policies) {
      displayPolicy(policies, doc);
    } else {
      const _policies = await checkPolicies();
      setPolicies(_policies);
      displayPolicy(_policies, doc);
    }
  };

  const closeModal = () => {
    setDisplayModal(false);
    setModalHTML("");
    ref.current.focus();
  };

  return (
    <footer>
      <button
        className="link"
        style={{ display: "block", margin: "0 auto", fontSize: "0.9em" }}
        onClick={() => {
          showPolicy("TC");
        }}
      >
        {language.labels.app.terms_conditions}
      </button>

      <button
        className="link"
        style={{ display: "block", margin: "1em auto", fontSize: "0.9em" }}
        onClick={() => {
          showPolicy("PP");
        }}
      >
        {language.labels.app.privacy_policy}
      </button>

      <Modal
        className="modal-dynamic"
        title={modalTitle}
        show={displayModal}
        language={language}
      >
        <section
          className="focus-modest"
          id="modal_body"
          aria-label={modalTitle}
          tabIndex={0}
        ></section>
        <div style={{ display: "flex", paddingBottom: "0.5em" }}>
          <button className="button-on" onClick={closeModal}>
            {language.labels.app.action_close}
          </button>
        </div>
      </Modal>
    </footer>
  );
});

export default Footer;
