import axios from "axios";
import config from "../config";
import refreshToken from "./refreshToken";
import { sessionExpired } from "./common";

const axiosCall = async (api, data, loader = true) => {
  // check we haven't timed out first
  let timedout = await sessionExpired();

  if (timedout) {
    return { error: true, message: "expired" };
  } else {
    let configurationObject = {
      url: `${config.server.api}/${api}/hub/${localStorage.getItem(
        "client_id"
      )}`,
      withCredentials: true,
      credentials: "include",
    };
    if (data) {
      configurationObject.method = "POST";
      configurationObject.data = data;
    } else {
      configurationObject.method = "GET";
    }
    if (loader) document.getElementById("loader").style.display = "inline";
    try {
      let response = await axios(configurationObject);
      if (response.status === 202) {
        let r = await refreshToken();
        if (loader) document.getElementById("loader").style.display = "none";
        if (r) {
          return { refresh: true };
        } else {
          //refresh token expired
          return { error: true, message: "refresh_token_expired" };
        }
      } else {
        if (loader) document.getElementById("loader").style.display = "none";
        return { success: true, data: response.data, status: response.status };
      }
    } catch (err) {
      if (loader) document.getElementById("loader").style.display = "none";
      return { error: true, message: err };
    }
  }
};
export default axiosCall;
