import { React, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import PasswordPage from "./signin/Password";
import SelectPage from "./signin/Select";
import CodePage from "./signin/Code";

import Footer from "../layout/Footer";
import PubBar from "../widgets/public/Bar";

const Login = ({ language, checkPolicies }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();
  const def_focus = useRef();

  const [page, setPage] = useState("password");
  const [uid, setUid] = useState("");
  const [cid, setCid] = useState("");
  const [targets, setTargets] = useState([]);
  const [target, setTarget] = useState({});

  ////////////// RENDER GUI //////////////

  return (
    <>
      <PubBar language={language} />
      <main className="login">
        <h1
          className="font-yongo login-heading"
          style={{
            textAlign: "center",
            marginBottom: "0.5em",
          }}
        >
          {language.labels.authentication.signin.yongo}
        </h1>

        {page === "password" ? (
          <PasswordPage
            language={language}
            navigate={navigate}
            def_focus={def_focus}
            setPage={setPage}
            setUid={setUid}
            setCid={setCid}
            setTargets={setTargets}
            setTarget={setTarget}
          />
        ) : page === "select" ? (
          <SelectPage
            language={language}
            targets={targets}
            uid={uid}
            cid={cid}
            setTarget={setTarget}
            setPage={setPage}
          />
        ) : (
          <CodePage
            language={language}
            navigate={navigate}
            def_focus={def_focus}
            uid={uid}
            cid={cid}
            target={target}
          />
        )}
      </main>

      {page === "password" && (
        <Footer
          language={language}
          checkPolicies={checkPolicies}
          ref={def_focus}
        />
      )}
    </>
  );
};

export default Login;
