import React, { useState, useEffect, useRef } from "react";
import { ImageEditor } from "../../../widgets";
import Pexels from "../../../../media/pexels.png";

const Image = ({
  next,
  animation,
  language,
  createBlock,
  dlp,
  setDlp,
  props,
}) => {
  //////////// INITIALIZATION ///////////
  //image editor file/URL setters
  const [file, setFile] = useState("");
  const [err, setErr] = useState("");
  const [imageSearch, setImageSearch] = useState("");

  //image editor ref
  const canvas_ref = useRef();

  useEffect(() => {
    if (props.bitesArr) {
      setImageSearch(encodeURIComponent(props.bitesArr[props.currBite]));
    } else {
      setImageSearch(encodeURIComponent(props.title));
    }

    if (animation && animation.step === 2) {
      if (animation.direction === 1) {
        document.getElementById("animateImage").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("animateImage").style.animationName =
          "slideout";
        document.getElementById("animateImage").style.animationDirection =
          "reverse";
      }
    }
  }, []);

  //////////// USER INTERACTIONS ///////////
  const submitForm = (e) => {
    e.preventDefault();
    setErr("");

    let canvas = canvas_ref.current;

    if (canvas && file) {
      var data_uri = canvas.toDataURL();
      createBlock(data_uri);
    } else {
      setErr(`${language.labels.bites.image_none}`);
    }
  };

  const back = () => {
    setDlp("");
    document.getElementById("animateImage").style.animationName = "slidein";
    document.getElementById("animateImage").style.animationDirection =
      "reverse";
    next({ step: 0, direction: -1 });
  };

  ////////////// RENDER GUI //////////////////
  const extras = props.extraSections.map((extra, index) => (
    <div style={{ marginTop: "0.5em", fontSize: "0.9em" }} key={index}>
      <div style={{ fontWeight: "600" }}>{extra.heading}</div>
      <div style={{ fontStyle: "italic" }}>{extra.description}</div>
    </div>
  ));

  return (
    <div style={{ position: "relative", maxWidth: "44rem" }}>
      <form
        id="animateImage"
        className="block-step page-section"
        onSubmit={(e) => submitForm(e)}
      >
        {props.title && (
          <h2
            className="heading2"
            style={{
              fontWeight: "500",
              fontSize: "1.1em",
              marginBottom: "0.25em",
            }}
          >
            {props.title}
          </h2>
        )}

        <div style={{ fontSize: "0.9em", fontStyle: "italic" }}>
          {props.description}
        </div>

        {extras}

        <h2
          className="heading2"
          style={{ fontWeight: "500", marginTop: "1em" }}
        >
          {language.labels.bites.image}
        </h2>

        <ImageEditor
          setFile={setFile}
          language={language}
          file={file}
          dim={400}
          ref={canvas_ref}
        />

        {err && (
          <div
            style={{ fontWeight: 600, marginTop: "0.5rem" }}
            className="errtext"
            role="alert"
          >
            {err}
          </div>
        )}

        <h2
          className="heading2"
          style={{ fontWeight: "500", marginTop: "1em" }}
        >
          {language.labels.bites.images.find}
        </h2>
        <a
          href={"https://www.pexels.com/search/" + imageSearch}
          target="_blank"
        >
          <img src={Pexels} style={{ marginTop: "0.25em", width: "150px" }} />
        </a>
        <div
          style={{ margin: "0.25em 0" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.bites.images.pexels.replace(
              /{search}/,
              imageSearch
            ),
          }}
        ></div>
        <div style={{ fontSize: "0.9em", fontStyle: "italic" }}>
          {language.labels.bites.images.tip}
        </div>

        {dlp && (
          <div
            style={{
              fontWeight: 600,
              marginTop: "1.5rem",
              marginBottom: "-1rem",
            }}
            className="errtext"
            role="alert"
          >
            {language.labels.error.dlp_bite[dlp]}
          </div>
        )}

        <div style={{ display: "flex", marginTop: "2em" }}>
          <div
            role="link"
            className="button-secondary"
            onClick={back}
            onKeyDown={(e) => e.key === "Enter" && back()}
            tabIndex={0}
          >
            {language.labels.app.action_back}
          </div>
          <div style={{ width: "0.25em" }}></div>
          <button
            className="button"
            style={{ borderWidth: "2px" }}
            type="submit"
          >
            {language.labels.bites.create}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Image;
