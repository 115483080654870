import axios from "axios";
import config from "../config";

const logoff = async (cid) => {
  try {
    if (document.getElementById("loader"))
      document.getElementById("loader").style.display = "none";
    const configurationObject = {
      url: `${config.server.api}/signout/hub/${cid}`,
      method: "GET",
      withCredentials: true,
      credentials: "include",
    };
    await axios(configurationObject);
  } catch (error) {
    console.error(`LOGGED ERROR: \n`, error);
  }
};

export default logoff;
