const supported = require('../locales/languages.json')
let locale
let labels

module.exports.getTranslator = () => {
    locale = navigator.language
    if(supported[locale]){
        labels = require('../locales/' + supported[locale])
        return {labels:labels, 'lang': locale, 'locale': locale}
    } else if(locale.length > 2 && supported[locale.substring(0,2)]) {
        labels = require('../locales/' + supported[locale.substring(0,2)])
        return {labels:labels, 'lang': locale.substring(0,2), 'locale': locale}
    } else {
        labels = require('../locales/default.json')
        return {labels:labels, 'lang': 'en', 'locale': 'en'}
    }
}