import React, { useState, useRef } from "react";

import config from "../../config";

import { togglePassword } from "../../lib/common";
import validator from "../../lib/validation";
import axiosCall from "../../lib/axios";

const Passwords = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////
  const password_ref = useRef();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [passwordStatus, setPasswordStatus] = useState("");
  const [showOld, setShowOld] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  ////////////// FUNCTIONS //////////////
  const changePassword = async (e) => {
    e.preventDefault();
    if (oldPassword && newPassword && newPasswordConfirm) {
      let pwd_chk = await validator.passwordValidator(newPassword);
      if (!pwd_chk) {
        password_ref.current.style.maxHeight = "300px";
        setPasswordStatus("password_invalid");
      } else if (newPassword !== newPasswordConfirm) {
        password_ref.current.style.maxHeight = "300px";
        setPasswordStatus("password_mismatch");
      } else {
        let result = await axiosCall("user/password/change", {
          oldPassword,
          newPassword,
        });
        if (result.success) {
          password_ref.current.style.maxHeight = "300px";
          setPasswordStatus(result.data);
          setOldPassword("");
          setNewPassword("");
          setNewPasswordConfirm("");
          setTimeout(() => {
            reset();
          }, 3000);
        } else if (result.refresh) {
          //token has been refreshed, try again
          changePassword(e);
        } else {
          // unknown error
          signout();
        }
      }
    } else {
      password_ref.current.style.maxHeight = "300px";
      setPasswordStatus("password_form_incomplete");
    }
  };

  const reset = () => {
    setOldPassword("");
    setNewPassword("");
    setNewPasswordConfirm("");
    password_ref.current.style.maxHeight = "0";
    setTimeout(() => {
      setPasswordStatus("");
    }, 500);
  };

  ////////////// RENDER GUI //////////////
  return (
    <form
      className="mobile-margin"
      onReset={reset}
      onSubmit={changePassword}
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "44rem",
        boxSizing: "border-box",
      }}
    >
      <div className="password">
        <input
          type={showOld ? "text" : "password"}
          className="natural-edit"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder={language.labels.account.password_current}
          autoComplete="off"
        />
        <div
          role="button"
          aria-label={language.labels.aria.password_show_hide}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && togglePassword(e.target, showOld, setShowOld)
          }
          tabIndex={0}
          onClick={(e) => togglePassword(e.target, showOld, setShowOld)}
        >
          -
        </div>
      </div>
      <div className="password">
        <input
          type={showNew ? "text" : "password"}
          className="natural-edit"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder={language.labels.account.password_new}
          autoComplete="off"
          maxLength={config.string.title}
        />
        <div
          role="button"
          aria-label={language.labels.aria.password_show_hide}
          onKeyUpCapture={(e) =>
            e.key === "Enter" && togglePassword(e.target, showNew, setShowNew)
          }
          tabIndex={0}
          onClick={(e) => togglePassword(e.target, showNew, setShowNew)}
        >
          -
        </div>
      </div>
      <div className="password">
        <input
          type={showConfirm ? "text" : "password"}
          className="natural-edit"
          value={newPasswordConfirm}
          onChange={(e) => setNewPasswordConfirm(e.target.value)}
          placeholder={language.labels.account.password_new_confirm}
          autoComplete="off"
        />
        <div
          role="button"
          aria-label={language.labels.aria.password_show_hide}
          onKeyUpCapture={(e) =>
            e.key === "Enter" &&
            togglePassword(e.target, showConfirm, setShowConfirm)
          }
          tabIndex={0}
          onClick={(e) => togglePassword(e.target, showConfirm, setShowConfirm)}
        >
          -
        </div>
      </div>
      <div ref={password_ref} className="trans500" style={{ maxHeight: "0" }}>
        {passwordStatus && (
          <div
            style={{ fontWeight: 600, marginBottom: "0.5rem" }}
            className={
              passwordStatus === "password_change_success"
                ? "successtext"
                : "errtext"
            }
            role="alert"
          >
            {language.labels.authentication[passwordStatus]}
          </div>
        )}
      </div>
      <div style={{ display: "flex", margin: "0.5em 0", gap: "0.25em" }}>
        <button className="button" style={{ fontSize: ".8em" }} type={"submit"}>
          {language.labels.app.action_submit}
        </button>
        <button
          className="button-cancel"
          style={{ fontSize: ".8em" }}
          type={"reset"}
        >
          {language.labels.app.action_clear}
        </button>
      </div>
    </form>
  );
};
export default Passwords;
