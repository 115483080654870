const countries = {
  calling_codes: [
    { country: "CA", code: "+1", flag: "🇨🇦" },
    { country: "US", code: "+1", flag: "🇺🇸" },
    { country: "MU", code: "+230", flag: "🇲🇺" },
    { country: "NA", code: "+264", flag: "🇳🇦" },
    { country: "LS", code: "+266", flag: "🇱🇸" },
    { country: "ZA", code: "+27", flag: "🇿🇦" },
    { country: "NL", code: "+31", flag: "🇳🇱" },
    { country: "BE", code: "+32", flag: "🇧🇪" },
    { country: "FR", code: "+33", flag: "🇫🇷" },
    { country: "ES", code: "+34", flag: "🇪🇸" },
    { country: "GI", code: "+350", flag: "🇬🇮" },
    { country: "PT", code: "+351", flag: "🇵🇹" },
    { country: "LU", code: "+352", flag: "🇱🇺" },
    { country: "IE", code: "+353", flag: "🇮🇪" },
    { country: "IS", code: "+354", flag: "🇮🇸" },
    { country: "MT", code: "+356", flag: "🇲🇹" },
    { country: "FI", code: "+358", flag: "🇫🇮" },
    { country: "HU", code: "+36", flag: "🇭🇺" },
    { country: "LT", code: "+370", flag: "🇱🇹" },
    { country: "LV", code: "+371", flag: "🇱🇻" },
    { country: "EE", code: "+372", flag: "🇪🇪" },
    { country: "AD", code: "+376", flag: "🇦🇩" },
    { country: "MC", code: "+377", flag: "🇲🇨" },
    { country: "SM", code: "+378", flag: "🇸🇲" },
    { country: "VA", code: "+379", flag: "🇻🇦" },
    { country: "SI", code: "+386", flag: "🇸🇮" },
    { country: "IT", code: "+39", flag: "🇮🇹" },
    { country: "CH", code: "+41", flag: "🇨🇭" },
    { country: "LI", code: "+423", flag: "🇱🇮" },
    { country: "AT", code: "+43", flag: "🇦🇹" },
    { country: "GB", code: "+44", flag: "🇬🇧" },
    { country: "DK", code: "+45", flag: "🇩🇰" },
    { country: "SE", code: "+46", flag: "🇸🇪" },
    { country: "NO", code: "+47", flag: "🇳🇴" },
    { country: "PL", code: "+48", flag: "🇵🇱" },
    { country: "DE", code: "+49", flag: "🇩🇪" },
    { country: "MY", code: "+60", flag: "🇲🇾" },
    { country: "AU", code: "+61", flag: "🇦🇺" },
    { country: "BN", code: "+673", flag: "🇧🇳" },
    { country: "FJ", code: "+679", flag: "🇫🇯" },
    { country: "CK", code: "+682", flag: "🇨🇰" },
    { country: "JP", code: "+81", flag: "🇯🇵" },
    { country: "KR", code: "+82", flag: "🇰🇷" },
    { country: "MO", code: "+853", flag: "🇲🇴" },
    { country: "LA", code: "+856", flag: "🇱🇦" },
    { country: "CN", code: "+86", flag: "🇨🇳" },
    { country: "TW", code: "+886", flag: "🇹🇼" },
    { country: "IN", code: "+91", flag: "🇮🇳" },
    { country: "MV", code: "+960", flag: "🇲🇻" },
  ],
  currencies: {
    default: { currency: "USD", symbol: "$" },
    // AT: { currency: "EUR", symbol: "€" },
    // AU: { currency: "AUD", symbol: "$" },
    // BF: { currency: "EUR", symbol: "€" },
    // BG: { currency: "EUR", symbol: "€" },
    // BJ: { currency: "EUR", symbol: "€" },
    // CY: { currency: "EUR", symbol: "€" },
    // CZ: { currency: "EUR", symbol: "€" },
    // DE: { currency: "EUR", symbol: "€" },
    // DK: { currency: "EUR", symbol: "€" },
    // ES: { currency: "EUR", symbol: "€" },
    // FI: { currency: "EUR", symbol: "€" },
    // FR: { currency: "EUR", symbol: "€" },
    // GA: { currency: "EUR", symbol: "€" },
    // GB: { currency: "GBP", symbol: "£" },
    // GI: { currency: "GBP", symbol: "£" },
    // GR: { currency: "EUR", symbol: "€" },
    // GW: { currency: "EUR", symbol: "€" },
    // HR: { currency: "EUR", symbol: "€" },
    // HU: { currency: "EUR", symbol: "€" },
    // IE: { currency: "EUR", symbol: "€" },
    // IT: { currency: "EUR", symbol: "€" },
    // LT: { currency: "EUR", symbol: "€" },
    // LU: { currency: "EUR", symbol: "€" },
    // LV: { currency: "EUR", symbol: "€" },
    // MC: { currency: "EUR", symbol: "€" },
    // ML: { currency: "EUR", symbol: "€" },
    // MT: { currency: "EUR", symbol: "€" },
    // NE: { currency: "EUR", symbol: "€" },
    // NL: { currency: "EUR", symbol: "€" },
    // PT: { currency: "EUR", symbol: "€" },
    // SK: { currency: "EUR", symbol: "€" },
    // SI: { currency: "EUR", symbol: "€" },
    // SM: { currency: "EUR", symbol: "€" },
    // TG: { currency: "EUR", symbol: "€" },
  },
};

export default countries;
