import { React, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

import config from "../../../../config";

const SelectPage = ({
  language,
  animation,
  next,
  page,
  registrations,
  setRegistration,
  setEmail,
  setPhone,
  setMessageResponse,
}) => {
  ////////////////// INITIALIZE //////////////////
  const cookies = new Cookies();
  const [heading, setHeading] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    if (!registrations.emails) {
      setHeading(language.labels.authentication.reset_sms);
    } else if (registrations.emails.length > 1 || registrations.phone) {
      setHeading(language.labels.authentication.reset_choose);
    } else {
      setHeading(language.labels.authentication.reset_mail);
    }
    // def_focus.current.focus();
    if (animation && animation.step === 1) {
      if (animation.direction === 1) {
        document.getElementById("select").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("select").style.animationName = "slideout";
        document.getElementById("select").style.animationDirection = "reverse";
      }
    }
  }, [registrations]);

  const nextStep = async (collection, id, mask) => {
    //init
    setErrMsg("");
    setRegistration(collection);
    let next_page = 2;
    if (collection === "phoneNumber") {
      setPhone(mask);
      if (page === "password") next_page = 3;
    } else {
      setEmail(mask);
    }
    try {
      const configurationObject = {
        url: `${config.server.api}/recover/code`,
        method: "POST",
        data: {
          recovery: cookies.get("recovery"),
          collection,
          id,
          language: language.lang,
        },
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        setMessageResponse(response.data);
        document.getElementById("select").style.animationName = "slideout";
        next({ step: next_page, direction: 1 });
      } else {
        setErrMsg(language.labels.error[response.data.message]);
      }
    } catch (error) {
      setErrMsg(language.labels.error.unknown);
      document.getElementById("loader").style.display = "none";
    }
  };

  return (
    <div
      className="signup"
      id="select"
      aria-label={language.labels.aria.form.password}
      style={{ paddingBottom: "2em" }}
    >
      <div style={{ textAlign: "center" }}>{heading}</div>

      {registrations.phone && (
        <>
          <h3
            className="heading2"
            style={{ marginTop: "2em", fontWeight: 500 }}
          >
            {language.labels.authentication.phone_number}
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5em 0",
            }}
          >
            <div style={{ flex: 1, fontWeight: 300 }}>
              {registrations.phone.mask}
            </div>
            <button
              className="button"
              style={{ fontSize: "0.8em" }}
              onClick={() =>
                nextStep(
                  "phoneNumber",
                  registrations.phone.id,
                  registrations.phone.mask
                )
              }
            >
              {language.labels.authentication.send}
            </button>
          </div>
        </>
      )}

      {registrations.emails && (
        <>
          <h3
            className="heading2"
            style={{ marginTop: "2em", fontWeight: 500 }}
          >
            {language.labels.authentication.email}
          </h3>
          {registrations.emails.map((mail, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0.5em 0",
              }}
            >
              <div style={{ flex: 1, fontWeight: 300 }}>{mail.mask}</div>
              <button
                className="button"
                style={{ fontSize: "0.8em" }}
                key={index}
                onClick={() => nextStep("email", mail.id, mail.mask)}
              >
                {language.labels.authentication.send}
              </button>
            </div>
          ))}
        </>
      )}

      {errMsg && (
        <div className="errtext" role="alert" style={{ marginTop: "1rem" }}>
          {errMsg}
        </div>
      )}
    </div>
  );
};

export default SelectPage;
