import React, { useState, useEffect, useRef, forwardRef } from "react";
import TextareaAutosize from "react-textarea-autosize";

import config from "../../config";
import { ImageEditor } from ".";
import axiosCall from "../../lib/axios";

const BlockEditor = forwardRef(
  (
    {
      readOnly,
      language,
      block,
      setBlock,
      setUpdateError,
      main_focus,
      signout,
    },
    ref
  ) => {
    //////////// INITIALIZE ///////////
    //Basic details
    const [title, setTitle] = useState(block.title);
    const [about, setAbout] = useState(block.about);
    const [extras, setExtras] = useState(block.extra);
    const title_ref = useRef();
    const about_ref = useRef();

    //Thumb
    const [showThumb, setShowThumb] = useState(false);
    const [file, setFile] = useState(null);
    const canvas_ref = useRef();

    //Extras
    const [extraId, setExtraId] = useState(null);
    const [extraDetail, setExtraDetail] = useState(null);
    const [newExtra, setNewExtra] = useState(false);
    const [newDetailErr, setNewDetailErr] = useState(null);
    const [blockExtras, setBlockExtras] = useState(null);
    const [extra, setExtra] = useState({});
    const extrasRef = useRef();

    useEffect(() => {
      if (newExtra && !blockExtras) getAllExtras();
    }, [newExtra]);

    //////////// EXTRAS ///////////

    const getAllExtras = async () => {
      let result = await axiosCall("blocks/extras/all", {
        lang: language.lang,
      });
      if (result.success) {
        setBlockExtras(result.data);
      } else if (result.refresh) {
        //token has been refreshed, try again
        getAllExtras();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    };

    const suggestExtra = (e) => {
      let results = [];
      for (let i = 0; i < blockExtras.length; i++) {
        let firstchars = e.target.value.trim().length;
        if (
          firstchars > 0 &&
          blockExtras[i].extra.substring(0, firstchars).toLowerCase() ===
            e.target.value.trim().toLowerCase()
        )
          results.push(blockExtras[i].extra);
      }
      setExtra(results);
    };

    const selectHeading = (index) => {
      document.getElementById("new_heading").value = extra[index];
      setExtra({});
      document.getElementById("new_detail").focus();
    };

    const chkInput = (e) => {
      if (e.key === "Enter") {
        setExtra({});
        document.getElementById("new_detail").focus();
      } else if (e.key === "ArrowDown" && extrasRef.current) {
        if (extrasRef.current.children.length > 0) {
          extrasRef.current.children[0].focus();
        }
      }
    };

    const chkKey = (e, index) => {
      e.preventDefault();
      if (index > -1) {
        if (e.key === "ArrowDown") {
          nextResult(e, index, 1);
        } else if (e.key === "ArrowUp") {
          nextResult(e, index, -1);
        } else if (e.key === "Enter") {
          selectHeading(index);
        }
      }
    };

    const nextResult = (e, index, direction) => {
      const nodelist = e.target.parentNode.children;
      const n = index + direction;
      if (n > -1 && n < nodelist.length) {
        e.target.parentNode.children[n].focus();
      } else if (n < 0) {
        document.getElementById("new_heading").focus();
      }
    };

    //////////// MISC FUNCTIONS ///////////

    function resetBlock(obj) {
      let _block = { ...block };
      //update block
      for (const key in obj) {
        _block[key] = obj[key];
        //update extras
        if (key === "extra") setExtras(obj[key]);
      }
      setBlock(_block);
    }

    function toggleImageEditor() {
      if (showThumb) setFile(null);
      setShowThumb(!showThumb);
    }

    //focus and blur
    const clearFocus = () => {
      resetThumb();
      setTitle(block.title);
      setAbout(block.about);
      setExtraId(null);
      setExtraDetail(null);
      setNewExtra(false);
    };
    const setBlur = (e, reblur = false, keyed = null) => {
      //timeout workaround to allow time for focus to shift if keyboard is used instead of mouse
      setTimeout(() => {
        if (reblur) {
          document.activeElement.blur();
        } else if (
          e.getAttribute("name") !== document.activeElement.getAttribute("name")
        ) {
          setExtraId(null);
          setExtraDetail(null);
          clearFocus();
        }
        if (keyed) {
          //set focus to next field
          let arr = ["title_field", "about_field"];
          for (var i = 0; i < extras.length; i++) {
            let field = "extra_" + i;
            arr.push(field);
          }
          const next = arr.indexOf(keyed) + 1;
          if (next > 0 && next < arr.length) {
            document.getElementById(arr[next]).focus();
          } else {
            main_focus.current.focus();
          }
        }
      }, 100);
    };

    function hideError() {
      ref.current.style.maxHeight = "0";
      setTimeout(() => {
        setUpdateError(null);
        ref.current.style.visibility = "hidden";
      }, 500);
    }

    function showError() {
      setUpdateError(language.labels.bites.error.delta);
      ref.current.style.visibility = "visible";
      ref.current.style.maxHeight = "200px";
      setTimeout(() => {
        hideError();
      }, 5000);
    }

    ////////////// TEXT DETAILS //////////////
    const changeExtra = (e) => {
      setExtraId(e.getAttribute("name"));
      setExtraDetail(e.value);
    };

    const removeExtra = async (index) => {
      let _extra = [];
      let remove = null;
      for (var i = 0; i < extras.length; i++) {
        if (i != index) {
          _extra.push(extras[i]);
        } else {
          remove = extras[i].heading;
        }
      }

      //try and submit
      let result = await axiosCall("block/edit", {
        bid: block._id,
        extra: _extra,
        remove,
      });
      if (result.success) {
        if (result.status === 200) {
          resetBlock({ extra: _extra });
        } else {
          showError();
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        addNewExtra();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    };

    const addNewExtra = async () => {
      const heading = document.getElementById("new_heading").value;
      const description = document.getElementById("new_detail").value;

      if (!heading.trim().length || !description.trim().length) {
        setNewDetailErr(language.labels.bites.error.new_detail);
        if (!heading.trim().length) {
          document.getElementById("new_heading").focus();
        } else {
          document.getElementById("new_detail").focus();
        }
      } else {
        //get extras
        let _extra = new Array();
        for (var i = 0; i < extras.length; i++) {
          _extra.push(extras[i]);
        }
        _extra.push({ heading, description });

        //try and submit
        let result = await axiosCall("block/edit", {
          bid: block._id,
          extra: _extra,
          add: heading,
        });
        if (result.success) {
          setNewExtra(false);
          if (result.status === 200) {
            resetBlock({ extra: _extra });
            setNewDetailErr(null);
          } else {
            showError();
          }
        } else if (result.refresh) {
          //token has been refreshed, try again
          addNewExtra();
        } else {
          //refresh token expired or unknown error
          signout();
        }
      }
    };

    const changeBasic = async (e, change = true) => {
      let val = e.target.getAttribute("name");
      let keyed = null;
      if (e.key) keyed = val;

      //check for changes
      if (
        !change ||
        (val === "title_field" &&
          (title === block.title || title.trim() === "")) ||
        (val === "about_field" &&
          (about === block.about || about.trim() === ""))
      ) {
        //just reset and close editor
        setBlur(val, true, keyed);
        return;
      }

      //get data
      let data = { bid: block._id };
      if (val.substring(0, 6) === "extra_") {
        if (extraDetail && extraDetail.trim() !== "") {
          //update extras
          const x = val.split("_");
          let block_cpy = [];
          for (var i = 0; i < extras.length; i++) {
            if (i == x[1]) {
              block_cpy.push({
                heading: extras[i].heading,
                description: extraDetail.trim(),
              });
            } else {
              block_cpy.push(extras[i]);
            }
          }
          data.extra = block_cpy;
        } else {
          //just reset and close editor
          setBlur(val, true, keyed);
          return;
        }
      } else if (val === "title_field") {
        data["title"] = title.trim();
      } else if (val === "about_field") {
        data["about"] = about.trim();
      }

      let result = await axiosCall("block/edit", data);
      if (result.success) {
        if (result.status === 200) {
          resetBlock(data);
        } else {
          showError();
          setBlur(val, true, keyed);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        changeBasic(e);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    };

    ////////////// THUMB //////////////
    const resetThumb = () => {
      setShowThumb(false);
      setFile(null);
    };

    const changeThumb = async (e, change = true) => {
      let click = true;
      if (e.key) click = false;

      if (!change) {
        resetThumb();
        if (!click) document.getElementById("toggle_image_editor").focus();
        return;
      }

      setFile(null);
      let canvas = canvas_ref.current;
      let data = { bid: block._id, current_thumb: block.thumb };
      if (canvas && file) {
        data.thumb = canvas.toDataURL();
      } else {
        resetThumb();
        if (!click) document.getElementById("toggle_image_editor").focus();
        return;
      }

      // try and submit data
      let result = await axiosCall("block/image", data);
      if (result.success) {
        resetBlock({ thumb: result.data });
        resetThumb();
        if (!click) document.getElementById("toggle_image_editor").focus();
      } else if (result.refresh) {
        //token has been refreshed, try again
        changeThumb(e);
      } else {
        //refresh token expired or unknown error
        signout();
      }
    };

    //////////// RENDER GUI ///////////
    const Extras = extras.map((extra, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "0.5em 0 1em 0",
        }}
      >
        <label className="text-label">{extra.heading}</label>
        <div
          className="natural-edit"
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "44rem",
            marginBottom: "0",
          }}
        >
          <TextareaAutosize
            minRows={4}
            style={{ width: "100%" }}
            id={`extra_${index}`}
            name={`extra_${index}`}
            value={
              extraId == `extra_${index}` ? extraDetail : extra.description
            }
            onChange={(e) => changeExtra(e.target)}
            onFocus={() => clearFocus()}
            onBlur={(e) => setBlur(e.target)}
            placeholder={language.labels.bites.detail.description_enter}
            maxLength={config.string.paragraph}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flex: 0,
              margin: "0 -0.5rem",
            }}
          >
            <div
              role="button"
              className="glyphs accept"
              name={`extra_${index}`}
              title={language.labels.app.apply}
              aria-label={language.labels.app.apply}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => {
                changeBasic(e);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e)
              }
              tabIndex={0}
            >
              *
            </div>
            <div
              role="button"
              className="glyphs reject"
              name={`extra_${index}`}
              style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
              title={language.labels.app.discard}
              aria-label={language.labels.app.discard}
              onBlur={(e) => setBlur(e.target)}
              onClick={(e) => {
                changeBasic(e, false);
              }}
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") && changeBasic(e, false)
              }
              tabIndex={0}
            >
              x
            </div>
          </div>
        </div>
        <div>
          <span
            role="link"
            className="handle hover"
            dangerouslySetInnerHTML={{
              __html: language.labels.bites.remove_extra.replace(
                /{extra}/g,
                extra.heading
              ),
            }}
            onClick={(e) => {
              removeExtra(index);
            }}
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") && removeExtra(index)
            }
            tabIndex={0}
          />
        </div>
      </div>
    ));

    return (
      <>
        {/* THUMB*/}
        {readOnly ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginTop: "0.5em",
            }}
          >
            <div
              className="avatar med"
              style={{
                flexShrink: "0",
                backgroundImage: `url(${
                  config.content.server + block.poster_image
                })`,
                borderRadius: "6px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 30px)",
              }}
            >
              <div className="name" style={{ fontSize: "1.1em" }}>
                {block.poster_name}
              </div>
              <div className="font-contrast" style={{ fontSize: "0.9em" }}>
                {language.labels.bites.third_party.toLowerCase()}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "44rem",
            }}
          >
            <div
              className="thumb-block border"
              style={{
                backgroundImage: `url("${
                  config.content.server + block.thumb
                }")`,
              }}
            >
              <button
                id="toggle_image_editor"
                className="button-edit left50"
                style={{
                  padding: "0 .5em",
                  position: "absolute",
                  bottom: "-0.5em",
                }}
                title={language.labels.app.change}
                aria-label={language.labels.app.change}
                onClick={() => {
                  toggleImageEditor();
                }}
              >
                <div aria-hidden="true" className="glyphs font-contrast">
                  w
                </div>
                <div style={{ padding: "0 .2rem" }}></div>
                <div style={{ flexGrow: 0 }}>{language.labels.app.change}</div>
              </button>
            </div>
          </div>
        )}

        {showThumb && !readOnly && (
          <ImageEditor
            style={{ maxWidth: "44rem", marginTop: "5px" }}
            file={file}
            setFile={setFile}
            language={language}
            dim={240}
            apply={changeThumb}
            ref={canvas_ref}
          />
        )}

        {/* TITLE */}
        {!readOnly && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "44rem",
              marginTop: "1.5rem",
            }}
          >
            <label className="text-label" htmlFor="title_field">
              {language.labels.bites.title}
            </label>
            <div
              className="natural-edit"
              style={{ display: "flex", maxWidth: "44rem" }}
            >
              <input
                autoComplete="off"
                style={{ flex: 1 }}
                id="title_field"
                name="title_field"
                value={title}
                placeholder={language.labels.bites.title_enter}
                onChange={(e) => setTitle(e.target.value)}
                onFocus={() => clearFocus()}
                onBlur={(e) => setBlur(e.target)}
                onKeyDown={(e) => e.key === "Enter" && changeBasic(e)}
                maxLength={config.string.title}
              />
              <div
                style={{
                  flexBasis: "3.5rem",
                  display: "flex",
                  justifyContent: "space-around",
                  margin: "-0.25rem",
                }}
              >
                <div
                  role="button"
                  className="glyphs accept"
                  name="title_field"
                  title={language.labels.app.apply}
                  aria-label={language.labels.app.apply}
                  onBlur={(e) => setBlur(e.target)}
                  onClick={(e) => changeBasic(e, "title_field")}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") && changeBasic(e)
                  }
                  tabIndex={0}
                >
                  *
                </div>
                <div
                  role="button"
                  className="glyphs reject"
                  name="title_field"
                  style={{ fontSize: "0.7rem" }}
                  title={language.labels.app.discard}
                  aria-label={language.labels.app.discard}
                  onBlur={(e) => setBlur(e.target)}
                  onClick={(e) => {
                    changeBasic(e, false);
                  }}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") &&
                    changeBasic(e, false)
                  }
                  tabIndex={0}
                >
                  x
                </div>
              </div>
            </div>
          </div>
        )}

        {/* ABOUT */}
        {!readOnly ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "44rem",
              marginTop: "1rem",
            }}
          >
            <label className="text-label" htmlFor="about_field">
              {language.labels.bites.description.about}
            </label>
            <div
              className="natural-edit"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "44rem",
              }}
            >
              <TextareaAutosize
                minRows={4}
                style={{ width: "100%" }}
                id="about_field"
                name="about_field"
                value={about}
                onChange={(e) => setAbout(e.target.value)}
                onFocus={() => clearFocus()}
                onBlur={(e) => setBlur(e.target)}
                placeholder={language.labels.bites.description.enter}
                maxLength={config.string.paragraph}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0,
                  margin: "0 -0.5rem",
                }}
              >
                <div
                  role="button"
                  className="glyphs accept"
                  name="about_field"
                  title={language.labels.app.apply}
                  aria-label={language.labels.app.apply}
                  onBlur={(e) => setBlur(e.target)}
                  onClick={(e) => {
                    changeBasic(e);
                  }}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") && changeBasic(e)
                  }
                  tabIndex={0}
                >
                  *
                </div>
                <div
                  role="button"
                  className="glyphs reject"
                  name="about_field"
                  style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
                  title={language.labels.app.discard}
                  aria-label={language.labels.app.discard}
                  onBlur={(e) => setBlur(e.target)}
                  onClick={(e) => {
                    changeBasic(e, false);
                  }}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") &&
                    changeBasic(e, false)
                  }
                  tabIndex={0}
                >
                  x
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "44rem",
              marginTop: "1.5rem",
            }}
          >
            <label className="text-label">
              {language.labels.bites.description.about}
            </label>
            <div style={{ padding: "0.25rem" }}>{about}</div>
          </div>
        )}

        {/* EXTRA */}
        {extras.length > 0 && (
          <>
            <h2
              className="heading2"
              style={{ marginTop: "1em", fontWeight: "600" }}
            >
              {language.labels.bites.more_details}
            </h2>
            {Extras}
          </>
        )}
        {extras.length < config.max_block_details && !newExtra && !readOnly && (
          <div style={{ margin: `${extras.length > 0 ? "0" : "1em 0"}` }}>
            <span
              className="link"
              tabIndex={0}
              onClick={() => setNewExtra(true)}
              onKeyUp={(e) =>
                (e.key === "Enter" || e.key === " ") && setNewExtra(true)
              }
            >
              {extras.length > 0
                ? language.labels.bites.detail.add_another
                : language.labels.bites.detail.add_more}
            </span>
          </div>
        )}
        {newExtra && !readOnly && (
          <div style={{ marginTop: "1em", maxWidth: "44rem" }}>
            <h3 className="heading3" style={{ fontWeight: "500" }}>
              {language.labels.bites.detail.new}
            </h3>
            <input
              autoFocus={true}
              id="new_heading"
              style={{ width: "100%" }}
              onChange={(e) => suggestExtra(e)}
              placeholder={language.labels.bites.detail.heading_new}
              onKeyUpCapture={(e) => chkInput(e)}
              maxLength={config.string.title}
            />
            {Object.keys(extra).length > 0 && (
              <div style={{ position: "relative" }}>
                <div
                  role="list"
                  aria-label="search results"
                  className="tag-search-results"
                  ref={extrasRef}
                  style={
                    extra.length > 0
                      ? {
                          minHeight: `Calc(1px + ${
                            0.25 + 1.75 * Math.min(28, extra.length)
                          }em)`,
                        }
                      : { borderBottom: "none" }
                  }
                >
                  {extra.map((data, idx) => (
                    <div
                      role="listitem"
                      aria-label={data}
                      className="combo-wrap focus-modest"
                      key={idx}
                      onMouseOver={(e) => {
                        e.target.focus();
                      }}
                      onClick={(e) => selectHeading(idx)}
                      onKeyDown={(e) => {
                        chkKey(e, idx);
                      }}
                      tabIndex="0"
                    >
                      <div aria-hidden="true">{data}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div
              className="natural-edit"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "44rem",
              }}
            >
              <TextareaAutosize
                id="new_detail"
                minRows={4}
                style={{ width: "100%" }}
                placeholder={language.labels.bites.detail.description_new}
                maxLength={config.string.paragraph}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 0,
                  margin: "0 -0.5rem",
                }}
              >
                <div
                  role="button"
                  className="glyphs accept"
                  title={language.labels.app.apply}
                  aria-label={language.labels.app.apply}
                  onClick={() => {
                    addNewExtra();
                  }}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") && addNewExtra()
                  }
                  tabIndex={0}
                >
                  *
                </div>
                <div
                  role="button"
                  className="glyphs reject"
                  style={{ fontSize: "0.7rem", margin: "0 0.25rem" }}
                  title={language.labels.app.discard}
                  aria-label={language.labels.app.discard}
                  onClick={(e) => {
                    setNewExtra(false);
                  }}
                  onKeyDown={(e) =>
                    (e.key === "Enter" || e.key === " ") && setNewExtra(false)
                  }
                  tabIndex={0}
                >
                  x
                </div>
              </div>
            </div>
            {newDetailErr && (
              <div
                className="errtext"
                role="alert"
                style={{ marginTop: "-0.25em" }}
              >
                {newDetailErr}
              </div>
            )}
          </div>
        )}
      </>
    );
  }
);

export default BlockEditor;
