import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = { id: "" };

export const contextSlice = createSlice({
  name: "context",
  initialState: { value: initialStateValue },
  reducers: {
    setContext: (state, action) => {
      state.value = action.payload;
    },

    unsetContext: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setContext, unsetContext } = contextSlice.actions;

export default contextSlice.reducer;
