import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import axiosCall from "../../../lib/axios";
import validator from "../../../lib/validation";

const CommunityMembership = ({ language, signout }) => {
  ////////////// INITIALIZE //////////////////
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  let codeTimer;
  const duration = 72e5; // 2 hours = 2 * 60 * 60 * 1000 = 72e5 ms
  const expiryLabel = useRef();

  const [memberCount, setMemberCount] = useState(0);
  const [payingCount, setPayingCount] = useState(0);
  const [passCode, setPassCode] = useState({});

  useEffect(() => {
    if (auth.admin || auth.level > 1) getMembership();
    getMembership(PassCode("retrieve"));
  }, []);

  useEffect(() => {
    if (passCode.created) setTimer(passCode.created);
  }, [passCode]);

  const getMembership = async () => {
    let data = { type: community.type };
    if (auth.admin) data.cid = community.id;
    let result = await axiosCall("community/membership", data, false);
    if (result.success) {
      setMemberCount(result.data.total);
      if (result.data.paying) setPayingCount(result.data.paying);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getMembership();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////// PASS CODE //////////////////

  const killTimer = () => {
    clearInterval(codeTimer);
    if (expiryLabel.current) expiryLabel.current.innerText = "";
    setPassCode({});
  };

  const setTimer = (timestamp) => {
    //init
    let expires_ms = Date.parse(timestamp) - Date.now() + duration;
    let minutes = Math.round(expires_ms / 6e4);
    expiryLabel.current.innerText =
      language.labels.members.passcode.expires.replace(/{time}/g, minutes);
    codeTimer = setInterval(() => {
      expires_ms = Date.parse(timestamp) - Date.now() + duration;
      minutes = Math.round(expires_ms / 6e4);
      console.log(expires_ms);
      if (expires_ms > 0 && expiryLabel.current) {
        expiryLabel.current.innerText =
          language.labels.members.passcode.expires.replace(/{time}/g, minutes);
      } else {
        killTimer();
      }
    }, 60000);
  };

  ////////////// PASS CODE //////////////////
  const PassCode = async (action) => {
    if (community.type === "institution") {
      let data = { action };
      killTimer();
      let result = await axiosCall("community/passcode", data, false);
      if (result.success) {
        if (action !== "expire") setPassCode(result.data);
      } else if (result.refresh) {
        //token has been refreshed, try again
        PassCode(action);
      } else {
        //refresh token expired or unknown error
        //   signout();
      }
    }
  };

  ////////////// RENDER GUI //////////////////
  return (
    <>
      {(auth.admin || auth.level > 1) && (
        <div style={{ marginTop: "0.5em" }}>
          {/* total member count */}
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              fontWeight: "500",
            }}
          >
            <div>{language.labels.members.total}</div>
            <div>{validator.bigNumber(memberCount)}</div>
          </div>

          {/* paying member count */}
          {community.type === "public" && community.remittance && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "0.5em",
                  marginTop: "0.5em",
                }}
              >
                <div>{language.labels.members.paying}</div>
                <div>{validator.bigNumber(payingCount)}</div>
              </div>
              <div
                style={{
                  marginTop: "4px",
                  fontSize: "0.9em",
                  fontStyle: "italic",
                }}
              >
                {language.labels.members.paying_explained}
              </div>
            </>
          )}
        </div>
      )}

      {community.type === "institution" && (
        <>
          <div style={{ padding: "0.5em 0" }}>
            {passCode.code ? (
              <>
                <div>{language.labels.members.passcode.usage}</div>
                <div
                  style={{
                    marginTop: "0.5em",
                    display: "flex",
                    gap: "1em",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: 500, fontSize: "1.2em" }}>
                    {passCode.code}
                  </div>
                  <div ref={expiryLabel}></div>
                </div>
                <button
                  className="button"
                  style={{ margin: "0.5em 0", fontSize: "0.9em" }}
                  onClick={() => PassCode("expire")}
                >
                  {language.labels.members.passcode.expire}
                </button>
              </>
            ) : (
              <>
                <div>{language.labels.members.passcode.require}</div>
                <button
                  className="button"
                  style={{ margin: "0.5em 0", fontSize: "0.9em" }}
                  onClick={() => PassCode("generate")}
                >
                  {language.labels.members.passcode.generate}
                </button>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default CommunityMembership;
