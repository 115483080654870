import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import config from "../../config";
import ios from "../../media/ios.svg";
import android from "../../media/android.svg";

const Deeplink = ({ language, os }) => {
  const navigate = useNavigate();
  const [isPageFocused, setIsPageFocused] = useState(false);
  const refPage = useRef("/");

  useEffect(() => {
    if (localStorage.getItem("previous_page"))
      refPage.current = "/" + localStorage.getItem("previous_page");

    if (os !== "desktop") {
      // try to open deeplink
      window.location.href = config.app_deeplink;

      if (localStorage.getItem("mobile_sso")) {
        //user has come to hub from app, assume app is installed. Just go back
        navigate(refPage.current);
      } else {
        // User has come to hub directly, unsure if app is installed. Change state on page focus and blur
        const handleFocus = () => {
          setIsPageFocused(true);
        };
        const handleBlur = () => {
          setIsPageFocused(false);
        };

        // Add event listeners for focus and blur on the window
        window.addEventListener("focus", handleFocus);
        window.addEventListener("blur", handleBlur);

        // Cleanup the event listeners on component unmount
        return () => {
          window.removeEventListener("focus", handleFocus);
          window.removeEventListener("blur", handleBlur);
        };
      }
    }
  }, []);

  return (
    <>
      <main className="main-page">
        <div
          className="page-section"
          style={{
            paddingBottom: "3em",
            maxWidth: "44rem",
          }}
        >
          {os === "desktop" ? (
            <>
              <div
                className="public-center"
                style={{
                  fontSize: "1.3em",
                  fontWeight: 400,
                  paddingBottom: "1em",
                }}
              >
                {language.labels.brand.available[os]}
              </div>
              <div
                className="public-center"
                style={{ display: "flex", gap: "1em" }}
              >
                <a href={config.app_download.ios} target="_blank">
                  <img className="hoverX" src={ios} />
                </a>

                <a href={config.app_download.android} target="_blank">
                  <img className="hoverX" src={android} />
                </a>
              </div>
            </>
          ) : (
            <>
              {isPageFocused ? (
                <div className="public-center">
                  <div>{language.labels.deeplink.not_installed}</div>
                  <div style={{ marginTop: "1em" }}>
                    <a href={config.app_download.ios} target="_blank">
                      <img
                        className="hoverX"
                        src={os === "android" ? android : ios}
                      />
                    </a>
                  </div>
                  <div style={{ marginTop: "2em" }}>
                    <button
                      className="link"
                      style={{ fontSize: "1.2em" }}
                      onClick={() => navigate(refPage.current)}
                    >
                      {language.labels.deeplink.return_hub}
                    </button>
                  </div>
                </div>
              ) : (
                <div className="dotdotdot">
                  {language.labels.deeplink.launching}
                </div>
              )}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default Deeplink;
