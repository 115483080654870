import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axiosCall from "../../lib/axios";
import { naturalDate } from "../../lib/date";
import config from "../../config";
import { Search } from "../widgets";

const Pathways = ({ language, main_focus, signout }) => {
  //////////// INITIALIZATION ///////////
  const navigate = useNavigate();
  const community = useSelector((state) => state.context.value);
  const auth = useSelector((state) => state.authentication.value);

  const search_ref = useRef();

  const [allPathways, setAllPathways] = useState([]);
  const [pathways, setPathways] = useState([]);
  const [filter, setFilter] = useState("all");
  const [term, setTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    getPathways();
  }, []);

  const getPathways = async () => {
    let result = await axiosCall("pathways/community");
    if (result.success) {
      setAllPathways(result.data);
      setPathways(result.data);
    } else if (result.refresh) {
      //token has been refreshed, try again
      getPathways();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  //////////// FILTER SEARCH ///////////
  const searchPathways = () => {
    if (search_ref.current?.value) {
      filterPathways(search_ref.current.value, "SEARCH");
    } else {
      filterPathways("", "SEARCH");
    }
  };

  const filterButton = (e) => {
    if (filter === e.value) {
      setFilter("all");
      filterPathways("all", "FILTER");
    } else {
      setFilter(e.value);
      filterPathways(e.value, "FILTER");
    }
  };

  const toggleFilter = () => {
    if (showFilter) filterPathways("all", "FILTER");
    setShowFilter(!showFilter);
  };

  const filterPathways = async (val, flag) => {
    let _filter = { term: term, filter: filter };
    if (flag === "SEARCH") {
      setTerm(val);
      _filter.term = val.trim();
    } else if (flag === "FILTER") {
      setFilter(val);
      _filter.filter = val;
    }

    let _pathways = [...allPathways];
    let filtered = _pathways.filter(
      (p) =>
        p.pathway.toLowerCase().includes(_filter.term.toLowerCase()) &&
        (_filter.filter === "pending" ? p.pending.length > 0 : true) &&
        (_filter.filter === "empty"
          ? p.bites.length === 0 && p.pending.length === 0
          : true)
    );
    setPathways(filtered);
  };

  //////////// NAVIGATION ///////////
  //navigate list with keys
  const bumpPos = (contents, direction) => {
    //find current position in list
    let pos = -1;
    var j = 0,
      len = contents.length;
    while (j < len) {
      if (contents[j] === document.activeElement) {
        pos = j;
        j = len;
      }
      j++;
    }
    pos = +pos + direction;
    if (pos < 0) pos = 0;
    if (pos >= len) pos = len - 1;

    contents[pos].focus();
  };
  const navResults = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let direction = 1;
      if (e.key === "ArrowUp") direction = -1;
      let container = document.getElementById("pwyList");
      let contents = container.querySelectorAll("div.card-long");
      bumpPos(contents, direction);
    }
  };

  //navigate to block
  const getInfo = (id) => {
    navigate("/pathway", { state: { pid: id } });
  };

  //////////// RENDER GUI ///////////

  const mappedResults = pathways.map((pwy, index) => (
    <div
      role="figure"
      aria-label={pwy.pathway}
      key={index}
      className="card-long hover"
      onClick={() => {
        getInfo(pwy._id);
      }}
      onKeyDown={(e) => navResults(e)}
      tabIndex={0}
    >
      {pwy?.bite_thumb ? (
        <div
          role="img"
          aria-label={pwy.pathway}
          style={{
            backgroundImage: `url(${config.content.server + pwy.bite_thumb})`,
            width: "90px",
            height: "90px",
          }}
          className="thumb-block auto-margin-narrow"
        ></div>
      ) : (
        <div
          role="img"
          aria-label={pwy.pathway}
          className="thumb-glyph auto-margin-narrow"
        >
          p
        </div>
      )}

      <div className="block-info">
        <div
          role="link"
          className="block-title"
          onKeyDown={(e) => e.key === "Enter" && getInfo(pwy._id)}
          tabIndex={0}
        >
          {pwy.pathway}
        </div>
        {pwy.description && (
          <div className="handle2" style={{ margin: "0.2em 0 0" }}>
            {pwy.description}
          </div>
        )}
        <div style={{ fontSize: "0.8em", margin: "0.2em 0 0.5em" }}>
          {naturalDate(pwy.updated, language.locale, language.labels.date)}
        </div>
        {pwy.bite_count > 0 ? (
          <div style={{ display: "flex", gap: "0.5em" }}>
            <div>{language.labels.pathways.bites}</div>
            <div>{pwy.bite_count}</div>
          </div>
        ) : (
          <div>{language.labels.pathways.no_bites}</div>
        )}
        {pwy.pending && pwy.pending.length > 0 && (
          <div
            style={{ display: "flex", gap: "0.5em", marginTop: "0.125em" }}
            className="font-contrast"
          >
            <div>{language.labels.pathways.pending}</div>
            <div>{pwy.pending.length}</div>
          </div>
        )}
      </div>
    </div>
  ));

  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div className="page-section" style={{ display: "flex" }}>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div
            role="img"
            aria-label={language.labels.aria.logo.replace(
              /{org}/g,
              community.title
            )}
            className="menu-crest"
            style={{
              height: "3em",
              width: "3em",
              backgroundImage: `url("${
                config.content.server + community.crest
              }")`,
            }}
          ></div>
          <div style={{ width: "0.5em" }}></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "calc(100% - 2.5em)",
            }}
          >
            <h1 className="heading" style={{ width: "100%", padding: "0" }}>
              {language.labels.navigation.pathways}
            </h1>
            <div className="handle2" style={{ width: "100%" }}>
              {community.title}
            </div>
          </div>
        </div>
      </div>

      {/* add pathway */}
      {auth.level > 1 && (
        <div className="page-section">
          <div
            style={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              marginTop: "1em",
            }}
          >
            <button
              className="button-glyph"
              onClick={() => navigate("/newpathway")}
            >
              +
            </button>
            <div>
              <span className="link" onClick={() => navigate("/newpathway")}>
                {language.labels.pathways.add.one}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="page-section">
        {allPathways.length === 0 && auth.level > 1 ? (
          <>
            <div>{language.labels.pathways.none}</div>
            <button
              className="button"
              style={{ marginTop: "2em" }}
              onClick={() => navigate("/newpathway")}
            >
              {language.labels.pathways.add.now}
            </button>
          </>
        ) : allPathways.length === 1 ? (
          <div>{language.labels.pathways.one}</div>
        ) : (
          <div>
            {language.labels.pathways.total.replace(
              /{n}/g,
              mappedResults.length
            )}
          </div>
        )}
      </div>

      {/* search control */}
      {allPathways.length > 3 && (
        <div
          className="page-section"
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "44em",
            gap: "0.25em",
            marginTop: "0.5em",
          }}
        >
          <div>
            <label className="text-label">
              {language.labels.pathways.filter_title}
            </label>
            <Search
              search_ref={search_ref}
              filter={toggleFilter}
              onChange={searchPathways}
              placeholder={language.labels.pathways.search_filter}
            />
            {showFilter && (
              <>
                <label
                  className="text-label"
                  style={{
                    display: "block",
                    fontSize: "0.8em",
                    marginTop: "0.5em",
                  }}
                >
                  {language.labels.app.filter}
                </label>
                <div
                  className="filter"
                  style={{
                    display: "flex",
                    gap: "0.5em",
                    marginTop: "0.25em",
                  }}
                >
                  <button
                    className={filter === "empty" ? "on" : "off"}
                    value="empty"
                    onClick={(e) => filterButton(e.target)}
                  >
                    {language.labels.pathways.filters.empty}
                  </button>
                  <button
                    className={filter === "pending" ? "on" : "off"}
                    value="pending"
                    onClick={(e) => filterButton(e.target)}
                  >
                    {language.labels.pathways.filters.pending}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <div className="page-section" style={{ maxWidth: "66rem" }}>
        {(filter !== "all" || term.trim() !== "") && (
          <div>
            {language.labels.pathways.total_match.replace(
              /{n}/g,
              mappedResults.length
            )}
          </div>
        )}
        <div id="pwyList">{mappedResults}</div>
      </div>
    </main>
  );
};

export default Pathways;
