import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Modal from "../../modal/Modal";
import config from "../../../config";
import axiosCall from "../../../lib/axios";
import validator from "../../../lib/validation";
import { naturalDate } from "../../../lib/date";
import Card from "../../../lib/previewLinks";

const Answers = ({
  community,
  language,
  answers,
  setEditor,
  setAnswerImages,
  setCurrentAnswerImage,
  answerViewerOpen,
  setAnswerViewerOpen,
  privateCommunity,
  onChange,
  signout,
}) => {
  //////////// INITIALIZE ///////////
  const userData = useSelector((state) => state.basic.value);
  const auth = useSelector((state) => state.authentication.value);

  const [answersHTML, setAnswersHTML] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [confirmClosure, setConfirmClosure] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState(null);

  useEffect(() => {
    fixAnswers();
  }, [answers]);

  //////////// DATA ///////////
  const processLinks = async (_answer_htm, _cards) => {
    let processed = [];
    for (const obj of _cards) {
      const result = await axiosCall(
        "knowledge/preview/link",
        { link: obj.link },
        false
      );
      const card = await Card({ ...result.data, link: obj.link });
      if (("processed index of", processed.indexOf(obj.link) < 0)) {
        processed.push(obj.link);
        if (_answer_htm[obj.index].indexOf(obj.card) > -1) {
          _answer_htm[obj.index] = _answer_htm[obj.index].replaceAll(
            obj.card,
            card
          );
          setAnswersHTML(_answer_htm);
        }
      }
    }
  };

  const deleteAnswer = async () => {
    let result = await axiosCall("knowledge/withdraw", {
      aid: currentAnswer,
    });
    if (result.success) {
      onChange(currentAnswer);
      setDeleteModal(false);
      setCurrentAnswer(null);
    } else if (result.refresh) {
      deleteAnswer();
    } else {
      signout();
    }
  };

  const withdrawAnswer = (aid) => {
    setCurrentAnswer(aid);
    setDeleteModal(true);
  };

  const openImageViewer = (index, n) => {
    let _images = [];
    if (privateCommunity) {
      for (const img of answers[index].images) {
        _images.push({ src: img.signed_url, name: img.originalname });
      }
    } else {
      for (const img of answers[index].images) {
        _images.push({
          src: config.content.cache + img.key,
          name: img.originalname,
        });
      }
    }
    setAnswerImages(_images);
    setCurrentAnswerImage(n);
    setAnswerViewerOpen(true);
  };

  const fixAnswers = async () => {
    let _answer_htm = new Array();
    let _cards = new Array();

    for (var i = 0; i < answers.length; i++) {
      answers[i].answer = true;
      if (answers[i].links && answers[i].links.length > 0) {
        let html = answers[i].post;
        for (const link of answers[i].links) {
          const card = await Card({ link });
          html = html.replaceAll(link, card);
          _cards.push({ card, link, index: i });
        }
        _answer_htm.push(html);
        setAnswersHTML(_answer_htm);
        if (i == answers.length - 1) processLinks(_answer_htm, _cards);
      } else {
        _answer_htm.push(answers[i].post);
        setAnswersHTML(_answer_htm);
        if (i == answers.length - 1) processLinks(_answer_htm, _cards);
      }
    }
  };

  const editAnswer = (e) => {
    let index = null;
    if (e.target.getAttribute("answerindex")) {
      index = e.target.getAttribute("answerindex");
    } else {
      index = e.target.parentNode.getAttribute("answerindex");
    }
    if (index) setEditor(answers[index]);
  };

  //////////// RENDER GUI ///////////

  return answers.map((_answer, index) => (
    <div key={index} style={{ paddingBottom: "1em" }}>
      <div className="line"></div>

      {/* heading and edit */}
      <div
        style={{
          display: "flex",
          marginTop: "1em",
          alignItems: "center",
          gap: "1em",
        }}
      >
        <h2
          className="heading2"
          style={{ fontSize: "1.1em", fontWeight: "600" }}
        >
          {language.labels.answer.answer}
        </h2>
      </div>

      {/* post */}
      <div style={{ display: "flex", marginTop: "1em" }}>
        {answersHTML && answersHTML[index] && (
          <div
            style={{ width: "100%", whiteSpace: "pre-wrap", maxWidth: "66rem" }}
            dangerouslySetInnerHTML={{ __html: answersHTML[index] }}
          />
        )}
      </div>

      {/* images */}
      {!answerViewerOpen && _answer.images.length > 0 && (
        <div style={{ margin: "1em -2px" }}>
          <div style={{ fontWeight: "500", margin: "0 2px" }}>
            {language.labels.app.image_count.replace(
              /{n}/g,
              _answer.images.length
            )}
          </div>
          {_answer.images.map((img, i) => (
            <div
              key={i}
              className="message-image-container"
              onClick={() => openImageViewer(index, i)}
              role="img"
              aria-label={img.originalname}
            >
              <img
                src={
                  img.signed_url
                    ? img.signed_url
                    : config.content.cache + img.key
                }
                className="message-image"
                aria-hidden="true"
                alt={img.originalname}
              />
            </div>
          ))}
        </div>
      )}

      {/* poster */}
      <div
        className="heading2"
        style={{ marginTop: "1em", fontStyle: "italic" }}
      >
        {language.labels.knowledge.by.answer}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          marginTop: "0.5em",
          maxWidth: "288px",
        }}
      >
        <div
          className="avatar med"
          style={{
            flexShrink: "0",
            backgroundImage: `url(${
              config.content.server + _answer.poster_image
            })`,
          }}
        ></div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="name" style={{ fontSize: "1em" }}>
            {_answer.poster_name}
          </div>
          {_answer.poster_endorsed && (
            <div className="contrast" style={{ fontSize: "11px" }}>
              {language.labels.app.expert}
            </div>
          )}
          <div style={{ fontSize: "0.7em", marginTop: "2px" }}>
            {naturalDate(
              _answer.created,
              language.locale,
              language.labels.date
            )}
          </div>
        </div>
      </div>

      {/* meta */}
      <div
        style={{
          display: "flex",
          margin: "0.5em",
          alignItems: "center",
          opacity: "0.8",
        }}
      >
        <div
          role="img"
          className="glyphs"
          style={{ flexBasis: "1.5em", fontSize: "0.9em" }}
          aria-label={language.labels.aria.likes}
          data-tooltip-id="answer-tooltip"
          data-tooltip-content={language.labels.aria.answers}
        >
          O
        </div>
        <div style={{ fontSize: "0.8em" }}>
          {validator.bigNumber(_answer.likes)}
        </div>
      </div>

      {/* edit */}
      {auth.role !== "role_instructor" &&
        (_answer.community === community.id ||
          _answer?.user === userData.uid) && (
          <button
            className="button-edit"
            style={{ padding: "0 1em", marginTop: "1.5em" }}
            answerindex={index}
            title={language.labels.knowledge.edit.answer}
            aria-label={language.labels.knowledge.edit.answer}
            onClick={(e) => {
              editAnswer(e);
            }}
          >
            <div aria-hidden="true" className="glyphs font-contrast">
              w
            </div>
            <div style={{ padding: "0 .2rem" }}></div>
            <div style={{ flexGrow: 0 }}>
              {language.labels.knowledge.edit.answer}
            </div>
          </button>
        )}

      {/* delete */}
      {auth.role !== "role_instructor" &&
        _answer.community &&
        community.id === _answer.community && (
          <button
            className="link"
            style={{ marginTop: "1.5em", fontSize: "0.9em" }}
            onClick={() => withdrawAnswer(_answer._id)}
          >
            {language.labels.knowledge.delete.answer}
          </button>
        )}

      {/* DELETE ANSWER MODAL */}
      <Modal
        className="modal-dynamic"
        title={language.labels.knowledge.delete.answer}
        show={deleteModal}
      >
        <div
          style={{ fontSize: "1.1em" }}
          dangerouslySetInnerHTML={{
            __html: language.labels.app.delete_warning,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontSize: ".9rem",
            marginTop: "1rem",
          }}
        >
          <input
            id="consequence_understood"
            type={"checkbox"}
            onChange={(e) => setConfirmClosure(e.target.checked)}
            defaultChecked={confirmClosure}
          />
          <div style={{ minWidth: "0.5rem" }}></div>
          <label className="hover" htmlFor="consequence_understood">
            {language.labels.app.consequences_understood}
          </label>
        </div>
        {confirmClosure && (
          <div style={{ display: "flex", marginTop: "0.5em" }}>
            <button className="button" onClick={() => deleteAnswer()}>
              {language.labels.knowledge.delete_now.answer}
            </button>
          </div>
        )}
        <div style={{ display: "flex", marginTop: "2em" }}>
          <button className="button-off" onClick={() => setDeleteModal(false)}>
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>
    </div>
  ));
};

export default Answers;
