import { React, useState } from "react";

import Modal from "../../modal/Modal";
import validator from "../../../lib/validation";
import axiosCall from "../../../lib/axios";
import config from "../../../config";

const InviteForm = ({ language, community, getInvites, signout, params }) => {
  ////////////////// INITIALIZE //////////////////
  const [invitees, setInvitees] = useState([]);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [emailErr, setEmailErr] = useState("");

  ////////////////// SEND INVITATIONS //////////////////
  const sendInvites = async (data) => {
    //reset page errors
    params.setShowResult(false);
    params.setError("");

    let result = await axiosCall("invite/staff", data);
    if (result.success) {
      if (result.status === 200) {
        params.setInvited(result.data.invited);
        params.setExisting(result.data.existing);
      } else {
        params.setInvited([]);
        params.setExisting([]);
        if (invitees.length > 1) {
          params.setError(language.labels.error.mailing_invites);
        } else {
          params.setError(language.labels.error.mailing_invite);
        }
      }
      onClose();
      getInvites();
    } else if (result.refresh) {
      //token has been refreshed, try again
      sendInvites(data);
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ////////////////// INVITEES //////////////////
  //keyboard interaction
  const checkInvitee = (e) => {
    if (e.key === "Enter") e.preventDefault();
    if (e.key === ";" || e.key === "," || e.key === "Enter" || e.key === " ")
      addInvitee(e);
  };

  const addInvitee = (e) => {
    setRoleErr("");
    setEmailErr("");
    let _email = email.replace(/[\,,;]/g, "").trim();

    //if event button click or key Enter then attempt submit
    let submit = false;
    if (e.type === "submit" || (e.type === "keyup" && e.key === "Enter"))
      submit = true;

    if (submit && invitees.length > 0 && _email.trim() === "") {
      //Enter key pressed with emails addresses already, so just submit
      onOK(e);
    } else if (validator.emailValidator(_email)) {
      //Check that email is valid
      setEmail("");
      if (invitees.indexOf(_email.toLowerCase()) < 0) {
        setInvitees([_email.toLowerCase(), ...invitees]);
        if (submit) onOK(e, [_email.toLowerCase(), ...invitees]);
      }
    } else {
      setEmailErr(language.labels.authentication.email_invalid);
    }
  };

  const deleteInvitee = (email) => {
    const inviteee_cpy = [...invitees];
    const idx = inviteee_cpy.findIndex((x) => x.email === email);
    if (idx > -1) {
      inviteee_cpy.splice(idx, 1);
      setInvitees(inviteee_cpy);
      document.getElementById("invitee_email").focus();
    }
  };

  ////////////////// MODAL //////////////////
  const onOK = async (e, invites) => {
    e.preventDefault();
    //reset modal errors
    setRoleErr("");
    setEmailErr("");
    //check role
    if (!role) {
      setRoleErr(language.labels.roles.role_err);
      return;
    }
    //check email
    if (!invites && email.trim() !== "") {
      //setEmailErr(language.labels.authentication.email_invalid)
      addInvitee(e);
      return;
    }
    //check invitees
    let _invitees = invitees;
    if (invites) _invitees = invites;
    if (_invitees.length === 0) {
      onClose();
      return;
    }

    let data = {
      community: community.id,
      language: language.lang,
      role,
      invitees: _invitees,
    };
    sendInvites(data);
  };

  const onClose = () => {
    //reset errors
    setRoleErr("");
    setEmailErr("");

    //reset data
    setRole("");
    setEmail("");
    setInvitees([]);

    //close modal
    params.setInviteForm(false);
  };

  ////////////// RENDER GUI //////////////////
  const to_invites = invitees.map((data, i) => (
    <div className="tag-wrap" aria-label={data.email} role="listitem" key={i}>
      <div className="tag tag_width" title={data}>
        <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
          {data}
        </div>
      </div>
      <div
        role="link"
        className="tag-x"
        onClick={() => {
          deleteInvitee(data.email);
        }}
        onKeyUpCapture={(e) =>
          (e.key === "Enter" || e.key === " ") && deleteInvitee(data)
        }
        tabIndex="0"
      ></div>
    </div>
  ));

  return (
    <Modal
      className="modal-dynamic"
      language={language}
      title={language.labels.invitation.invite}
      show={params.inviteForm}
      onClose={onClose}
    >
      <form onSubmit={(e) => onOK(e)} onReset={onClose}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label htmlFor="role_selector" className="text-label">
            {language.labels.roles.role_choose}
          </label>
          <div>
            <select
              onChange={(e) => {
                setRole(e.target.value);
              }}
              value={role}
              id="role_selector"
              autoFocus
            >
              <option value="" selected disabled hidden>
                {language.labels.app.choose_role}
              </option>
              <option value="role_instructor">
                {language.labels.roles.role_instructor}
              </option>
              <option value="role_content">
                {language.labels.roles.role_content}
              </option>
              <option value="role_director">
                {language.labels.roles.role_director}
              </option>
            </select>
          </div>
          {roleErr && (
            <div className="errtext" role="alert">
              {roleErr}
            </div>
          )}

          <label
            htmlFor="invitee_email"
            className="text-label"
            style={{ marginTop: "1.5em" }}
          >
            {language.labels.authentication.email_address}
          </label>
          <div className="subtext">
            {language.labels.invitation.emails_enter}
          </div>
          <input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            id="invitee_email"
            placeholder={language.labels.authentication.email_prompt}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            onKeyUpCapture={(e) => {
              checkInvitee(e);
            }}
            maxLength={config.string.email}
          />
          {emailErr && (
            <div className="errtext" role="alert">
              {emailErr}
            </div>
          )}
        </div>

        {to_invites && (
          <div style={{ margin: "0.5em 0" }}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {to_invites}
            </div>
          </div>
        )}

        <div style={{ fontWeight: 300, fontSize: "0.8em" }}>
          {language.labels.invitation.advice_mail}
        </div>

        <div style={{ display: "flex", marginTop: "2.5rem" }}>
          <button className="button-on" type="submit">
            {language.labels.app.ok}
          </button>
          <div style={{ width: "0.25em" }}></div>
          <button className="button-off" type="reset">
            {language.labels.app.cancel}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default InviteForm;
