const duration = (seconds, labels) => {
  if (seconds < 60) {
    const s = Math.round(seconds);
    return s + labels.second;
  } else {
    const m = Math.round(seconds / 60);
    const s = Math.round(seconds - 60 * m);
    return m + labels.minute + " " + s + labels.second;
  }
};

const naturalDate = (date, locale, labels) => {
  const _date = new Date(date);
  const seconds = Math.round((Date.now() - _date) / 1000);
  const mins = Math.round(seconds / 60);
  const hrs = Math.round(seconds / 3600);

  if (seconds < 2) {
    return labels.now;
  } else if (seconds < 59) {
    return labels.seconds.replace(/{n}/g, seconds);
  } else if (seconds < 91) {
    return labels.minute;
  } else if (seconds < 3480) {
    return labels.minutes.replace(/{n}/g, mins);
  } else if (seconds < 5430) {
    return labels.hour;
  } else if (seconds < 43230) {
    return labels.hours.replace(/{n}/g, hrs);
  } else if (seconds < 86460) {
    return (
      _date.toLocaleDateString(locale, {
        day: "numeric",
        month: "short",
        year: "numeric",
      }) +
      " " +
      _date.toLocaleTimeString(locale, { hour: "numeric", minute: "2-digit" })
    );
  } else {
    return _date.toLocaleDateString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }
};

const recentDate = (ts, unit = "day", period) => {
  let _then = new Date(ts);
  let _when = new Date();
  if (unit === "month") {
    _when.setMonth(_when.getMonth() - period);
  } else {
    _when.setDate(_when.getDate() - period);
  }
  return _then > _when;
};

export { duration, naturalDate, recentDate };
