import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  uid: "",
  username: "",
  avatar: "",
  phone_number: "",
  country: "",
  fullName: "",
  bio: "",
};

export const basicSlice = createSlice({
  name: "basic",
  initialState: { value: initialStateValue },
  reducers: {
    setBasic: (state, action) => {
      state.value = action.payload;
    },

    unsetBasic: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setBasic, unsetBasic } = basicSlice.actions;

export default basicSlice.reducer;
