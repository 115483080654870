import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = {
  client: "",
  agree_terms: "",
  account_type: "",
  created: "2020-01-01T00:00:00Z",
  admin: false,
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: { value: initialStateValue },
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },

    logout: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { login, logout } = authSlice.actions;

export default authSlice.reducer;
