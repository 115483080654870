// import config from "../config";

module.exports.countryCaller = async (country, codes) => {
  for (var i = 0; i < codes.length; i++) {
    if (codes[i].country == country) {
      return codes[i];
    }
  }
  return {};
};

module.exports.emailValidator = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

module.exports.passwordValidator = (pwd) => {
  var len = false;
  var alpha = false;
  var special = false;
  var space = false;

  if (pwd.length >= 8) {
    len = true;
  }

  var grp1 = /[a-z]/;
  var grp2 = /[A-Z]/;
  var grp3 = /[0-9]/;
  if ((grp1.test(pwd) || grp2.test(pwd)) && grp3.test(pwd)) {
    alpha = true;
  }

  var grp4 = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (grp4.test(pwd)) {
    special = true;
  }

  var grp5 = /\s/;
  if (grp5.test(pwd)) {
    space = true;
  }

  if (len && alpha && special && !space) {
    return true;
  } else {
    return false;
  }
};

module.exports.usernameValidator = (uname) => {
  var pattern = /^(?:[0-9a-zÀ-ú-_]+|[^\x00-\x7F]+)$/;
  return pattern.test(uname);
};

module.exports.URLchecker = (str) => {
  let url;
  try {
    url = new URL(str);
    return url;
  } catch (_) {
    return {};
  }
  // return url.protocol === "http:" || url.protocol === "https:" || (url.protocol === "data:" && str.substring(0, 11) === "data:image/");
};

module.exports.bigNumber = (number) => {
  let int = Math.round(number);
  if (int < 1000) {
    return int;
  } else if (int < 1000000) {
    const k = Math.round(int / 100) / 10;
    return k + "k";
  } else {
    const M = Math.round(int / 100000) / 10;
    return M + "M";
  }
};
