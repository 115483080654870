import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getIPLocation } from "react-ip-location";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

import config from "../../../../config";
import validator from "../../../../lib/validation";

const UsernameForm = ({
  language,
  animation,
  next,
  def_focus,
  cookies,
  username,
  setUsername,
}) => {
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [errReserved, setErrReserved] = useState(false);

  useEffect(() => {
    getCountry();
    def_focus.current.focus();
    if (animation && animation.step === 0) {
      if (animation.direction === 1) {
        document.getElementById("username").style.animationName = "slidein";
      } else if (animation.direction === -1) {
        document.getElementById("username").style.animationName = "slideout";
        document.getElementById("username").style.animationDirection =
          "reverse";
      }
    }
  }, []);

  const getCountry = async () => {
    let country = cookies.get("country");
    let uuid = cookies.get("uuid");
    if (!country || !uuid) {
      document.getElementById("loader").style.display = "inline";
      try {
        const location = await getIPLocation();
        cookies.set("country", location.country);
        // cookies.set("country", "-"); //test unknown country
        // cookies.set("country", "RU"); //test unsupported country
        cookies.set("uuid", uuidv4());
        document.getElementById("loader").style.display = "none";
      } catch (err) {
        cookies.set("country", "-");
        cookies.set("uuid", uuidv4());
        document.getElementById("loader").style.display = "none";
      }
    }
  };

  const reTry = (e, n) => {
    if (n < 3) {
      //reset uuid
      cookies.set("uuid", uuidv4());
      nextStep(e, n + 1);
    } else {
      //too many retries, clear cookies and reload
      cookies.remove("uuid");
      cookies.remove("uuid");
      window.location.reload();
    }
  };

  const nextStep = async (e, n = 0) => {
    //reset error message
    e.preventDefault();
    setErrMsg("");
    setErrReserved(false);

    //check username
    let _username = username.toLowerCase().trim();
    var uname_chk = validator.usernameValidator(_username);
    if (!uname_chk) {
      setErrMsg(language.labels.authentication.username_invalid);
      return;
    }

    // check username availability
    document.getElementById("loader").style.display = "inline";
    setErrMsg(language.labels.authentication.username_checking);

    try {
      //axios call
      const configurationObject = {
        url: `${config.server.api}/auth/stageuser`,
        method: "POST",
        data: {
          username: _username,
          country: cookies.get("country"),
          uuid: cookies.get("uuid"),
        },
      };
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        //go to next step
        document.getElementById("username").style.animationName = "slideout";
        next({ step: 1, direction: 1 });
      } else if (response.status === 202) {
        //uuid conflict
        reTry(e, n);
      } else {
        //duplicate user
        setErrMsg(language.labels.error.username_taken);
        setErrReserved(true);
      }
    } catch (err) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  return (
    <form
      className="signup"
      id="username"
      onSubmit={(e) => nextStep(e)}
      aria-label={language.labels.aria.form.signup}
      autoComplete="off"
    >
      <div
        style={{ fontSize: "1.1em", textAlign: "center", marginBottom: "1em" }}
      >
        {language.labels.authentication.join_free}
      </div>
      <div>{language.labels.authentication.username_reserve}</div>
      <input
        ref={def_focus}
        style={{ marginTop: "1rem", width: "100%" }}
        placeholder={language.labels.authentication.username_enter_a}
        aria-label={language.labels.authentication.username_enter_a}
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        maxLength={config.string.title}
      />
      {errMsg && (
        <div className="errtext" role="alert">
          {errMsg}
        </div>
      )}
      {errReserved && (
        <div
          style={{ fontSize: "0.7em", fontWeight: 300, marginTop: "0.5em" }}
          role="alert"
        >
          {language.labels.error.reservations}
        </div>
      )}

      <button
        className="button"
        type="submit"
        style={{ width: "100%", marginTop: "1.5rem", fontSize: "1.1rem" }}
      >
        {language.labels.app.action_next}
      </button>

      <div className="join-container">
        <div>{language.labels.authentication.have_account}</div>
        <div style={{ minWidth: "0.25em" }}></div>
        <div
          role="link"
          aria-label={language.labels.authentication.have_account}
          className="link"
          onClick={() => navigate("/login")}
          onKeyUpCapture={(e) => e.key === "Enter" && navigate("/login")}
          tabIndex={0}
        >
          {language.labels.authentication.signin.user}
        </div>
      </div>
    </form>
  );
};

export default UsernameForm;
