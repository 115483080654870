import { React, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import PubBar from "../widgets/public/Bar";
import config from "../../config";
import validator from "../../lib/validation";
import { togglePassword } from "../../lib/common";

const Password = ({ language }) => {
  ////////////////// INITIALIZE //////////////////
  const navigate = useNavigate();

  const pwd_field = useRef();
  const uname_field = useRef();

  const [passwordErr, setPasswordErr] = useState("");
  const [unameErr, setUnameErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    if (sessionStorage.getItem("password_reset")) {
      navigate("/login");
    } else {
      const params = new URLSearchParams(window.location.search);
      let _data = {};
      for (const p of params) {
        if (p[0] === "c") _data.code = p[1];
        if (p[0] === "r") _data.recovery = p[1];
      }
      if (_data.code && _data.recovery) {
        setData(_data);
        uname_field.current.focus();
      } else {
        navigate("/login");
      }
    }
  }, []);

  ////////////////// PROCESS CODE //////////////////
  const clearErrors = () => {
    setErrMsg("");
    setPasswordErr("");
    setUnameErr("");
  };

  const submit = async (e) => {
    //init
    e.preventDefault();
    clearErrors();
    let post_data = { ...data };
    let username = uname_field.current.value || "";
    let password = pwd_field.current.value;

    //check username
    if (username.toLowerCase().trim()) {
      post_data.username = username.toLowerCase().trim();
    } else {
      if (password) setUnameErr(language.labels.authentication.username_enter);
      uname_field.current.focus();
      return;
    }

    //check password
    var pwd_chk = validator.passwordValidator(password);
    if (pwd_chk) {
      post_data.password = password;
    } else {
      if (password)
        setPasswordErr(language.labels.authentication.password_invalid);
      pwd_field.current.focus();
      return;
    }

    try {
      document.getElementById("loader").style.display = "inline";
      const configurationObject = {
        url: `${config.server.api}/recover/password`,
        method: "POST",
        data: post_data,
      };
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        sessionStorage.setItem("password_reset", "OK");
        setSuccess(true);
      } else if (response.status === 201) {
        setErrMsg(language.labels.error.link_invalid);
      } else {
        setErrMsg(language.labels.error.unknown);
      }
    } catch (err) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  return (
    <>
      <PubBar language={language} />
      <main className="login">
        <h1
          className="font-yongo login-heading"
          style={{
            textAlign: "center",
            marginBottom: "0.5em",
          }}
        >
          {language.labels.authentication.password_reset}
        </h1>

        {success ? (
          <div style={{ textAlign: "center" }}>
            <div
              role="status"
              style={{
                fontWeight: "500",
                fontSize: "1.2em",
                paddingBottom: "1em",
                textAlign: "center",
              }}
              dangerouslySetInnerHTML={{
                __html: language.labels.authentication.password_reset_success,
              }}
            />
            <button
              className="button"
              style={{ marginTop: "2em" }}
              onClick={() => {
                navigate("/login");
              }}
            >
              {language.labels.authentication.go_to_signin}
            </button>
          </div>
        ) : (
          <form
            className="login-form"
            aria-label={language.labels.aria.form.password_reset}
            onSubmit={(e) => submit(e)}
            autoComplete="off"
          >
            {/* username input */}
            <label style={{ paddingTop: "1em", display: "block" }}>
              {language.labels.authentication.username}
            </label>
            <input
              ref={uname_field}
              style={{ width: "100%" }}
              className="input"
              placeholder={language.labels.authentication.username_enter}
              aria-label={language.labels.authentication.username_enter}
              autoComplete="one-time-code"
            />
            {unameErr && (
              <div className="errtext" role="alert">
                {unameErr}
              </div>
            )}

            {/* password input */}
            <label style={{ paddingTop: "1em", display: "block" }}>
              {language.labels.authentication.password_reset_new}
            </label>
            <div className="password" style={{ width: "100%" }}>
              <input
                ref={pwd_field}
                type={showPassword ? "text" : "password"}
                className="input"
                placeholder={language.labels.authentication.password}
                aria-placeholder={language.labels.authentication.password}
                maxLength={config.string.title}
                autoComplete="one-time-code"
              />
              <div
                role="button"
                aria-label={language.labels.aria.password_show_hide}
                onKeyUpCapture={(e) =>
                  e.key === "Enter" &&
                  togglePassword(e.target, showPassword, setShowPassword)
                }
                tabIndex={0}
                onClick={(e) =>
                  togglePassword(e.target, showPassword, setShowPassword)
                }
              >
                -
              </div>
            </div>
            {passwordErr && (
              <div className="errtext" role="alert">
                {passwordErr}
              </div>
            )}

            {errMsg && (
              <div
                style={{ fontWeight: 600, padding: "1.5em 0 0 0" }}
                className="errtext"
                role="alert"
              >
                {errMsg}
              </div>
            )}

            <button
              type="submit"
              className="button"
              style={{ width: "100%", marginTop: "3rem", fontSize: "1.1em" }}
            >
              {language.labels.app.action_submit}
            </button>
          </form>
        )}
      </main>
    </>
  );
};

export default Password;
