import CryptoJS from "crypto-js";
import { ClientJS } from "clientjs";

const encrypt = (JWT) => {
  // Get the client's fingerprint id
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  // return the 32bit hash
  return CryptoJS.AES.encrypt(JWT, "s" + fingerprint).toString();
};

const decrypt = (hash) => {
  // Get the client's fingerprint id
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();

  //return jwt
  var bytes = CryptoJS.AES.decrypt(hash, "s" + fingerprint);
  return bytes.toString(CryptoJS.enc.Utf8);
};

const payload = (hash) => {
  const jwt = decrypt(hash);
  const base64Url = jwt.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
};

const getPayload = () => {
  if (localStorage.tag) {
    return payload(localStorage.tag);
  } else {
    return {};
  }
};

export default { encrypt, decrypt, payload, getPayload };
