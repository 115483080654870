import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getIPLocation } from "react-ip-location";
import PhoneInput from "react-phone-number-input/input";
import {
  formatPhoneNumberIntl,
  parsePhoneNumber,
} from "react-phone-number-input";
import Cookies from "universal-cookie";
import axios from "axios";

import { setBasic } from "../../features/basic";

import countries from "../../countries";
import validator from "../../lib/validation";
import Modal from "../modal/Modal";
import config from "../../config";
import axiosCall from "../../lib/axios";

const Phone = ({ language, signout }) => {
  ////////////////// INITIALIZE //////////////////
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.basic.value);

  const code_ref = useRef();
  const phone_ref = useRef();
  const code_message = useRef();
  const code_check = useRef();

  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [errCode, setErrCode] = useState("");
  const [editor, setEditor] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [localNumber, setLocalNumber] = useState();
  const [iso, setIso] = useState({});
  const [country, setCountry] = useState(false);
  const [international, setInternational] = useState(false);
  const [newCountry, setNewCountry] = useState("");
  const [messageResponse, setMessageResponse] = useState({});

  useEffect(() => {
    getCountry();
  }, []);

  ////////////////// FIX COUNTRY //////////////////
  const getCountry = async () => {
    let country = cookies.get("country");
    if (!country) {
      document.getElementById("loader").style.display = "inline";
      try {
        const location = await getIPLocation();
        cookies.set("country", location.country);
        countryIso(location.country);
      } catch (err) {
        cookies.set("country", "-");
        document.getElementById("loader").style.display = "none";
      }
    } else {
      countryIso(cookies.get("country"));
    }
  };

  const countryIso = async (country) => {
    for (var i = 0; i < countries.calling_codes.length; i++) {
      if (countries.calling_codes[i].country == country) {
        setIso(countries.calling_codes[i]);
        setCountry(true);
        document.getElementById("loader").style.display = "none";
        break;
      }
    }
  };

  ////////////// PHONE NUMBER //////////////
  const submitPhone = async (data) => {
    let result = await axiosCall("user/phone/register", data);
    if (result.success) {
      if (result.status === 200) {
        setMessageResponse(result.data);
        setShowModal(true);
      } else {
        setErrMsg(language.labels.error[result.data.error]);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      submitPhone(data);
    } else {
      // unknown error
      signout();
    }
  };

  const checkPhone = async () => {
    //clear errors
    setErrMsg("");

    //validate phone
    let phone = {};
    let data = { language: language.lang };
    if (localNumber) phone = parsePhoneNumber(localNumber);
    if (phone?.country) {
      let country_obj = await validator.countryCaller(
        phone.country,
        countries.calling_codes
      );
      if (country_obj.country) {
        setNewCountry(country_obj.country);
        data.phone = localNumber;
        submitPhone(data);
      } else {
        setErrMsg(
          language.labels.account.phone_unsupported.replace(
            /{caller}/g,
            phone.countryCallingCode
          )
        );
      }
    } else {
      setErrMsg(language.labels.authentication.phone_invalid);
    }
  };

  ////////////// VERIFICATION //////////////
  const messageStatus = async () => {
    code_check.current.style.display = "none";
    code_message.current.innerText =
      language.labels.authentication.status.checking;
    code_message.current.className = "dotdotdot";

    const configurationObject = {
      url: `${config.server.api}/recover/message`,
      method: "POST",
      data: messageResponse,
    };
    code_message.current.className = "";

    try {
      const response = await axios(configurationObject);
      code_message.current.innerText = language.labels.authentication.sms[
        response.data.status
      ].replace(/{number}/g, localNumber);

      setTimeout(() => {
        if (
          response.data.status === "canceled" ||
          response.data.status === "failed" ||
          response.data.status === "undelivered"
        ) {
          cancel();
        } else {
          code_check.current.style.display = "";
          code_message.current.innerText = "";
        }
      }, 3000);
    } catch (err) {
      code_check.current.style.display = "";
      code_message.current.innerText = "";
      setErrCode(language.labels.error.unknown);
    }
  };

  const cancel = () => {
    setLocalNumber("");
    setErrMsg("");
    setCode("");
    setErrCode("");
    setEditor(false);
    setShowModal(false);
  };

  const verify = async () => {
    let result = await axiosCall("user/phone/verify", {
      phone: localNumber,
      country: newCountry,
      code,
    });
    if (result.success) {
      if (result.status === 200) {
        //SUCCESS
        let userState = { ...userData };
        userState.phone_number = localNumber;
        userState.country = newCountry;
        localStorage.setItem("basic", JSON.stringify(userState));
        dispatch(setBasic(userState));
        cancel();
      } else {
        setErrCode(language.labels.error.code_invalid);
      }
    } else if (result.refresh) {
      //token has been refreshed, try again
      verify();
    } else {
      //refresh token expired or unknown error
      signout();
    }
  };

  ///////////////// RENDER GUI /////////////////

  return (
    <>
      <div className="mobile-center">
        {editor ? (
          <>
            <div
              className="mobile-justify"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              {country && !international && (
                <div aria-label={iso.country + " " + iso.code}>
                  {iso.flag + " " + iso.code}
                </div>
              )}
              {country && !international ? (
                <PhoneInput
                  ref={phone_ref}
                  international={true}
                  country={iso.country}
                  placeholder={language.labels.authentication.phone_enter_your}
                  value={localNumber}
                  onChange={setLocalNumber}
                  onKeyUpCapture={(e) => e.key === "Enter" && checkPhone()}
                />
              ) : (
                <PhoneInput
                  ref={phone_ref}
                  international={true}
                  placeholder={language.labels.authentication.phone_enter_your}
                  value={localNumber}
                  onChange={setLocalNumber}
                  onKeyUpCapture={(e) => e.key === "Enter" && checkPhone()}
                />
              )}
            </div>

            {iso.code && !international ? (
              <div>
                <button
                  className="link"
                  style={{ fontSize: "0.9em", fontWeight: 300 }}
                  onClick={() => {
                    setLocalNumber("");
                    setErrMsg("");
                    setInternational(true);
                    phone_ref.current.focus();
                  }}
                >
                  {language.labels.authentication.wrong_caller.replace(
                    /{caller}/g,
                    iso.code
                  )}
                </button>
              </div>
            ) : (
              <label style={{ fontSize: "0.9em", fontWeight: 300 }}>
                {language.labels.authentication.phone_caller}
              </label>
            )}
            {errMsg && <div className="errtext">{errMsg}</div>}

            <div
              className="mobile-justify"
              style={{ display: "flex", marginTop: "1em", gap: "0.25em" }}
            >
              <button
                className="button"
                style={{ fontSize: ".8em" }}
                onClick={checkPhone}
                onKeyUpCapture={(e) =>
                  (e.key === "Enter" || e.key === " ") && checkPhone()
                }
              >
                {language.labels.app.action_submit}
              </button>
              <button
                className="button-cancel"
                style={{ fontSize: ".8em" }}
                onClick={cancel}
              >
                {language.labels.app.cancel}
              </button>
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: "0.5em" }}>
              {userData.phone_number
                ? formatPhoneNumberIntl(userData.phone_number)
                : language.labels.app.not_provided}
            </div>
            <div>
              <button
                className="link"
                style={{ marginTop: "1em" }}
                onClick={() => setEditor(true)}
              >
                {userData.phone_number
                  ? language.labels.account.phone_change
                  : language.labels.account.phone_add}
              </button>
            </div>
          </>
        )}
      </div>

      <Modal
        show={showModal}
        language={language}
        title={language.labels.authentication.code_heading}
        className={"modal-dynamic"}
        onOk={verify}
        onClose={cancel}
      >
        {/* input */}
        <div style={{ marginTop: "0.5em" }}>
          {language.labels.authentication.code_sms_prompt}
        </div>
        <input
          ref={code_ref}
          style={{ marginTop: "0.5rem", width: "100%" }}
          placeholder={language.labels.authentication.code}
          aria-label={language.labels.authentication.code}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          onKeyUpCapture={(e) =>
            (e.key === "Enter" || e.key === " ") && verify()
          }
          tabIndex="0"
          autoFocus
        />

        {/* message status */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "0.25rem",
          }}
        >
          <div
            ref={code_check}
            role="link"
            className="subtext link"
            onClick={messageStatus}
            onKeyDown={(e) =>
              (e.key === "Enter" || e.key === " ") && messageStatus()
            }
            tabIndex="0"
          >
            {language.labels.authentication.code_not_received}
          </div>
          <div ref={code_message}></div>
        </div>

        {/* submit errors */}
        {errCode && (
          <div
            style={{ fontWeight: 600, marginBottom: ".5rem" }}
            className="errtext"
            role="alert"
          >
            {errCode}
          </div>
        )}
      </Modal>
    </>
  );
};

export default Phone;
