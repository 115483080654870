import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { CommunityPublic, GetApp, PubBar, PubFooter } from "../widgets";
import config from "../../config";
// import validator from "../../lib/validation";

const PubCommunity = ({ language, os }) => {
  ////////////// INITIALIZE //////////////
  const navigate = useNavigate();
  const [community, setCommunity] = useState([]);

  ////////////// GET COMMUNITY //////////////
  useEffect(() => {
    let uname = "";
    if (sessionStorage.getItem("community")) {
      const _community = JSON.parse(sessionStorage.getItem("community"));
      uname = _community.uname;
    }

    if (window.location.hash.substring(1)) {
      if (window.location.hash.substring(1) === uname) {
        let _community = JSON.parse(sessionStorage.getItem("community"));
        setCommunity(_community);
      } else {
        //get community details
        const configurationObject = {
          url: `${
            config.server.api +
            "/public/community?uname=" +
            window.location.hash.substring(1)
          }`,
          method: "GET",
        };
        axios(configurationObject)
          .then((response) => {
            if (response.status === 200) {
              //set community
              sessionStorage.setItem(
                "community",
                JSON.stringify(response.data)
              );
              setCommunity(response.data);
            } else {
              //no community
              navigate("/find_community");
            }
            document.getElementById("loader").style.display = "none";
          })
          .catch((error) => {
            //no community
            navigate("/find_community");
            document.getElementById("loader").style.display = "none";
          });
      }
    } else {
      //no community
      navigate("/find_community");
    }
  }, []);

  ////////////// RENDER GUI //////////////

  return (
    <main>
      <PubBar language={language} buttons={true} />

      {/* bread-crumb trail */}
      <div
        style={{
          display: "flex",
          padding: "1em 0.5em",
          justifyContent: "center",
          gap: "0.5em",
        }}
      >
        <button className="link" onClick={() => navigate("/find_community")}>
          {language.labels.navigation.communities.toUpperCase()}
        </button>
        <div>/</div>
        <div>{community.uname}</div>
      </div>

      <div className="public section" style={{ paddingBottom: "6em" }}>
        {/* community public details */}
        <CommunityPublic
          community={community}
          language={language}
          website={true}
        />

        {/* type */}
        <div style={{ textAlign: "center", marginTop: "1.5em" }}>
          {language.labels.public.community_types[community.type]}
        </div>
        <div
          className="center"
          style={{ margin: "0.25em 0 2em 0", fontSize: "0.9em" }}
        >
          {community.private
            ? language.labels.public.private_members.replace(
                /{community}/g,
                community.uname
              )
            : language.labels.public.public_join.replace(
                /{community}/g,
                community.uname
              )}
        </div>

        <GetApp os={os} language={language} myclass="center" />
        <div className="center" style={{ fontSize: "0.8em", margin: "0.25em" }}>
          {language.labels.public.limited}
        </div>
      </div>

      <PubFooter language={language} />
    </main>
  );
};

export default PubCommunity;
