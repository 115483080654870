import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = "";

export const communitiesSlice = createSlice({
  name: "communities",
  initialState: { value: initialStateValue },
  reducers: {
    setCommunities: (state, action) => {
      state.value = action.payload;
    },

    unsetCommunities: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setCommunities, unsetCommunities } = communitiesSlice.actions;

export default communitiesSlice.reducer;
