import { createSlice } from "@reduxjs/toolkit";

const initialStateValue = [];

export const emailsSlice = createSlice({
  name: "emails",
  initialState: { value: initialStateValue },
  reducers: {
    setEmails: (state, action) => {
      state.value = action.payload;
    },

    unsetEmails: (state) => {
      state.value = initialStateValue;
    },
  },
});

export const { setEmails, unsetEmails } = emailsSlice.actions;

export default emailsSlice.reducer;
